import React from 'react';
import { Link } from 'react-router-dom';

import { BasicLayout, Card, Footer } from 'src/common';
import { useAuth0 } from 'src/lib/hooks';

const InvalidResumption = ({ email }) => {
  const { logout } = useAuth0();

  const onSubmit = () => {
    logout();
  };

  return (
    <BasicLayout displayNavElements={false}>
      <Card
        icon="bummer"
        title="Bummer."
        subtitle={
          <>
            <p>
              We don’t have any quotes in progress for
              <span className="font-semibold"> {email}</span>.
            </p>
            <p>
              Would you like to
              <Link to="/" className="text-curious-blue-dark pl-1 underline">
                start a new quote?
              </Link>
            </p>
          </>
        }
      />
      <Footer>
        <Footer.Next onClick={onSubmit} rounded label="Get Started" />
      </Footer>
    </BasicLayout>
  );
};

export default InvalidResumption;
