import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import {
  desktopBGBuildingsLeft,
  desktopBGBuildingsRight,
  desktopBGCloudsLeft,
  desktopBGCloudsRight,
  desktopBGWaves,
} from 'src/assets/img';
import config from 'src/config';
import { useFeatureFlags, useModel } from 'src/lib/hooks';
import classnames from 'tailwindcss-classnames';
import AnnouncementBar from '../AnnouncementBar';

const Viewport = ({ children }) => {
  const { showMaintenanceTimeWarning, maintenanceMode } = useFeatureFlags();
  const { BindingRestriction } = useModel.quote();

  const getBgClasses = () => {
    const noAnnouncement = !BindingRestriction && !showMaintenanceTimeWarning;
    const hasBinding = BindingRestriction && !showMaintenanceTimeWarning;
    const hasMaintenance = !BindingRestriction && showMaintenanceTimeWarning;
    const hasBoth = BindingRestriction && showMaintenanceTimeWarning;
    return {
      '-bottom-0.5': maintenanceMode || noAnnouncement,
      'bottom-8': !maintenanceMode && (hasBinding || hasMaintenance),
      'bottom-24 md:bottom-16': !maintenanceMode && hasBoth,
    };
  };

  const BgClouds = ({ className, fixedHeight }) => (
    <div
      className={classnames('absolute top-16 w-full h-5/6', className)}
      style={{
        background: [
          `url("${desktopBGCloudsLeft}") no-repeat top left/auto ${fixedHeight}px`,
          `url("${desktopBGCloudsRight}") no-repeat top right/auto ${fixedHeight}px`,
        ].join(','),
      }}
    />
  );

  const BgGround = ({ className, fixedHeight }) => (
    <div
      className={classnames('h-64 absolute w-full', getBgClasses(), className)}
      style={{
        background: [
          `url("${desktopBGWaves}") repeat-x bottom/auto ${fixedHeight}px`,
          `url("${desktopBGBuildingsRight}") no-repeat bottom right/auto ${fixedHeight}px`,
          `url("${desktopBGBuildingsLeft}") no-repeat bottom left/auto ${fixedHeight}px`,
          'linear-gradient(rgba(255,255,255,0), white)',
        ].join(','),
      }}
    />
  );

  return (
    <div className="flex flex-col min-h-screen relative bg-spindle overflow-hidden xl:h-screen">
      <BgClouds className="hidden lg:block" fixedHeight={500} />
      <BgGround className="hidden lg:block xl:hidden" fixedHeight={200} />
      <BgGround className="hidden xl:block" fixedHeight={256} />

      <div
        id="scrolling-container"
        className="xl:overflow-auto xl:relative h-full"
      >
        <header id="viewport-header" />
        <main className="flex-auto px-4 pt-0 xs:px-6 xl:pt-6 pb-48 md:pb-28">
          {children}
        </main>
        <section className="fixed bottom-0 w-full">
          <div>
            {!maintenanceMode && BindingRestriction && (
              <AnnouncementBar
                role="alert"
                className="bg-kournikova text-daintree text-sm font-content"
                text={BindingRestriction.Message}
                showLink={true}
                textLink="Learn More"
                href="https://www.skyway.com/binding-restriction"
                classNameLink="ml-1 underline "
              />
            )}
            {!maintenanceMode && showMaintenanceTimeWarning && (
              <AnnouncementBar
                role="alert"
                className="bg-yellow-500 text-daintree text-sm"
                text="Our systems will begin routine maintenance within the next 10 minutes.
                You can resume your quote where you left off after the break."
              />
            )}
          </div>
        </section>
        <section id="viewport-loading-content" />
      </div>
    </div>
  );
};
export default withLDProvider({
  clientSideID: config.launchDarkly.clientId,
  user: { key: 'anon' },
})(Viewport);
