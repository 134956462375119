import React from 'react';
import { useController } from 'react-hook-form';
import classNames from 'tailwindcss-classnames';

import { Input, Icon } from 'src/common';
import { currencyTransform, identityTransform } from 'src/lib/transforms';

const NumericInput = ({
  name,
  control,
  rules = {},
  defaultValue,
  label,
  error,
  min,
  max,
  step = 1000,
  className,
  containerClassName,
  transform = identityTransform,
  format = currencyTransform,
  ...inputProps
}) => {
  const customValidate = rules.validate;

  rules.validate = (value) => {
    const v = transform.input(value);
    if (min !== undefined && v < min) {
      return `The minimum value is ${currencyTransform.input(min)}.`;
    } else if (max !== undefined && v > max) {
      return `The maximum value is ${currencyTransform.input(max)}.`;
    } else if (step !== undefined && v % step !== 0) {
      return `Must be a multiple of ${currencyTransform.input(step)}.`;
    }
    return customValidate ? customValidate(value) : true;
  };

  const { field } = useController({
    name,
    rules,
    control,
    defaultValue,
  });

  const handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      increase(e);
    } else if (e.keyCode === 40) {
      decrease(e);
    }
  };

  const handleChange = (val) => {
    let changeTo = !!val || val === 0 ? Math.round(val / step) * step : null;
    if (changeTo < min) {
      changeTo = min;
    } else if (changeTo > max) {
      changeTo = max;
    }
    field.onChange(transform.output(changeTo));
  };

  const increase = (e) => {
    e.preventDefault();
    handleChange(Number(transform.input(field.value)) + step);
    field.ref.current.focus();
  };

  const decrease = (e) => {
    e.preventDefault();
    handleChange(Number(transform.input(field.value)) - step);
    field.ref.current.focus();
  };

  return (
    <div className={classNames(containerClassName, 'flex items-start')}>
      <button
        type="button"
        className={classNames(
          'bg-white border border-daintree rounded-l-full h-8 px-2 mt-1.5 mr-2.5 transition',
          'disabled:opacity-60 focus:outline-none focus:ring-2 ring-daintree-lighter focus:ring-offset-2 focus:ring-daintree'
        )}
        disabled={field.value <= min}
        onClick={decrease}
        aria-label="Decrease"
        title="Decrease"
      >
        <Icon name="minus" size="sm" />
      </button>
      <Input
        containerClassName="w-full flex flex-col"
        label={label}
        id={name}
        min={min}
        max={max}
        value={format.input(transform.input(field.value))}
        step={step}
        error={error}
        onChange={(e) => {
          const formatless = format.output(e.target.value);
          const transformed = transform.output(formatless);
          field.onChange(transformed);
        }}
        onKeyDown={handleKeyDown}
        ref={field.ref}
        {...inputProps}
      />
      <button
        type="button"
        className={classNames(
          'outline-none bg-daintree border border-daintree rounded-r-full h-8 px-2 mt-1.5 ml-2.5 transition',
          'disabled:opacity-60 focus:outline-none focus:ring-2 ring-daintree-lighter focus:ring-offset-2 focus:ring-daintree'
        )}
        disabled={field.value >= max}
        onClick={increase}
        aria-label="Increase"
        title="Increase"
      >
        <Icon name="plus" size="sm" color="white" />
      </button>
    </div>
  );
};

export default NumericInput;
