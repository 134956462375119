import React, { useCallback, useRef, useState } from 'react';
import classNames from 'tailwindcss-classnames';

import { Icon } from 'src/common';

const iconSize = {
  small: 'sm',
  default: 'md',
  rectangular: 'base',
};

const loadingIconSize = {
  small: 'w-2 h-2',
  rectangular: 'w-5 h-5',
  default: 'w-10 h-10',
};

const iconColor = {
  light: 'daintree',
  dark: 'mystic',
  blue: 'white',
  transparent: 'daintree',
};

const Button = ({
  onClick,
  color = 'light',
  variant = 'default',
  disabled = false,
  loading: _loading = false,
  children,
  className,
  fontFamily = 'font-content',
  iconLeft,
  iconRight,
  href,
  target,
  hideLabelWhenLoading = false,
  ...buttonProps
}) => {
  const [loading, setLoading] = useState(_loading);
  const onClickRef = useRef(onClick);
  onClickRef.current = onClick;

  const handleClick = useCallback(
    async (...props) => {
      try {
        setLoading(true);
        await onClickRef.current(...props);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, onClickRef]
  );

  const isLoading = loading || _loading;
  const hideChildren = hideLabelWhenLoading && isLoading;
  const effectiveColor = isLoading ? 'dark' : color;

  let iconRightComp = iconRight && (
    <Icon
      name={iconRight}
      color={iconColor[effectiveColor]}
      size={iconSize[variant]}
    />
  );

  if (isLoading) {
    iconRightComp = (
      <Icon name="beachBall" className={loadingIconSize[variant]} />
    );
  }

  const buttonClassName = classNames(
    {
      'bg-daintree text-mystic shadow-lg focus:ring-daintree':
        effectiveColor === 'dark',
      'bg-mystic text-daintree focus:ring-mystic': effectiveColor === 'light',
      'bg-curious-blue-light text-daintree focus:ring-daintree':
        effectiveColor === 'blue-light',
      'bg-curious-blue-dark text-white border-curious-blue-dark focus:ring-mystic':
        effectiveColor === 'blue',
      'bg-transparent text-daintree focus:ring-daintree border-none':
        effectiveColor === 'transparent',
      'border-mystic': effectiveColor === 'dark' && isLoading,
    },
    {
      'space-x-3 px-4 xs:px-6 h-12 2xl:h-14 border-2': variant === 'default',
      'flex-row space-x-2 px-2 xs:px-3 h-6 2xl:h-14 w-full':
        variant === 'menuMobile',
      'px-3 h-6': variant === 'small',
      'py-3 px-5 rounded-xl text-sm space-x-2': variant === 'rectangular',
      'rounded-full font-semibold space-x-1': variant !== 'rectangular',
    },
    'flex items-center font-heading transition',
    'focus:ring-2 focus:ring-offset-2 focus:outline-none focus:opacity-90',
    'disabled:cursor-not-allowed',
    className
  );

  const content = (
    <>
      {iconLeft && (
        <Icon
          name={iconLeft}
          color={iconColor[effectiveColor]}
          size={iconSize[variant]}
        />
      )}
      {!hideChildren && (
        <span
          className={classNames(fontFamily, {
            'text-lg 2xl:text-xl leading-4': variant === 'default',
            'text-lg 2xl:text-xl font-bold leading-4': variant === 'menuMobile',
            'text-xs 2xl:text-sm font-light': variant === 'small',
          })}
        >
          {children}
        </span>
      )}
      {iconRightComp}
    </>
  );

  return !href ? (
    <button
      disabled={isLoading || disabled}
      onClick={!isLoading && !disabled ? handleClick : null}
      className={buttonClassName}
      {...buttonProps}
    >
      {content}
    </button>
  ) : (
    <a
      href={href}
      className={buttonClassName}
      target={target}
      onClick={onClick}
      rel={target === '_blank' ? 'noreferrer' : ''}
    >
      {content}
    </a>
  );
};

export default Button;
