import { useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import classNames from 'tailwindcss-classnames';

import Icon from 'src/common/Icon';
import Button from '../Button';
import FormSkipButton from 'src/products/florida/ho6/windmit/FormSkipButton';

const FileInput = ({
  btnText = 'Upload form',
  control,
  name,
  defaultValue,
  rules,
  formName = 'form',
  FormType,
  nextPage,
  error: validationError,
}) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const {
    field: { value, onChange, ref },
  } = useController({ name, control, rules, defaultValue });

  useEffect(() => {
    onChange(defaultValue);
    setFile(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!value) {
      setFile(null);
      ref.current.value = null;
    }
  }, [value, ref]);

  const openFileBrowser = () => {
    ref.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setError(null);
      setFile(file);

      if (file.name.split('.').pop() !== 'pdf') {
        setError(
          <>
            This file type is not supported. Please upload a <br /> PDF, or{' '}
            <FormSkipButton
              isFooter={false}
              FormType={FormType}
              nextPage={nextPage}
            />
            .
          </>
        );
        onChange(undefined);
      } else {
        onChange(file);
      }
    }
  };

  return (
    <div className="flex flex-col space-y-6 items-center">
      <p className="text-center">
        <strong>Great!</strong> Please upload your {formName}.
      </p>
      {!file && (
        <>
          <label htmlFor={name} className="sr-only">
            {btnText}
          </label>
          <Button
            onClick={openFileBrowser}
            color="dark"
            variant="rectangular"
            iconLeft="arrowUp"
            type="button"
            {...(error && {
              'aria-describedby': `${name}-error`,
            })}
          >
            {btnText}
          </Button>
          {validationError && (
            <span className="text-piper text-sm">Form upload is required.</span>
          )}
        </>
      )}
      {file && (
        <div className="flex space-x-4">
          <Icon
            name="file"
            size="lg"
            className="mt-3"
            color={error ? 'piper' : 'daintree'}
          />
          <div className="flex flex-col space-y-1.5 text-daintree text-sm max-w-tiny sm:max-w-xs">
            <label
              htmlFor={name}
              className={classNames(
                'font-bold truncate',
                error && 'text-piper'
              )}
            >
              {file.name}
            </label>
            {error && (
              <p id={`${name}-error`} className="text-piper">
                {error}
              </p>
            )}
            <button
              type="button"
              onClick={openFileBrowser}
              className={classNames(
                'underline text-left',
                error && 'text-piper'
              )}
            >
              Replace File
            </button>
          </div>
        </div>
      )}
      <input
        id={name}
        ref={ref}
        type="file"
        className="hidden"
        onChange={handleFileChange}
        accept="application/pdf"
      />
    </div>
  );
};

export default FileInput;
