import React from 'react';
import classnames from 'tailwindcss-classnames';

const Error = ({ children, className, containerClassName }) => (
  <div className={classnames('px-2 pt-4 pb-6 w-full', containerClassName)}>
    <div
      role="alert"
      className={classnames(
        'text-piper bg-piper-lighter max-w-md rounded-lg p-2 mx-auto text-center border-piper border-2',
        className
      )}
    >
      {children}
    </div>
  </div>
);

export default Error;
