import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useModel } from 'src/lib/hooks';
import products from 'src/products';
import analytics from 'src/lib/analytics';

const Onboarding = () => {
  const { State, Product } = useModel.quote();

  useEffect(() => {
    analytics.page('Onboarding');
  }, []);

  const OnboardingContent = products?.[State]?.[Product];

  if (!OnboardingContent) {
    return <Redirect to="/" />;
  }

  return <OnboardingContent />;
};

export default Onboarding;
