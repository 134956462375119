export const PROGRESS = {
  EXPLORE_PRICING: {
    totalNotches: 8,
    filledNotches: 1,
    label: 'Explore Our Pricing',
  },
  PROCEED: {
    totalNotches: 8,
    filledNotches: 2,
    label: 'Proceed with Quote',
  },
  INTERVIEW: {
    totalNotches: 8,
    filledNotches: 3,
    label: 'Interview Questions',
  },
  COVERAGES: {
    totalNotches: 8,
    filledNotches: 4,
    label: 'Adjust Coverages',
  },
  DISCOUNTS: {
    totalNotches: 8,
    filledNotches: 5,
    label: 'Apply Discounts',
  },
  FINAL_STEPS: {
    totalNotches: 8,
    filledNotches: 6,
    label: 'Final Steps',
  },
  REVIEW_PAY: {
    totalNotches: 8,
    filledNotches: 7,
    label: 'Review & Pay',
  },
  SUCCESS: {
    totalNotches: 8,
    filledNotches: 8,
    label: 'Success!',
  },
};

export const WIND_MIT = {
  UNIFORM: 'Uniform',
  BUILDING: 'Building',
  WINDSTORM: 'Windstorm',
};

export const MISSING_INFO = {
  YEAR_BUILT: 'YearBuilt',
  SQUARE_FEET: 'SquareFeet',
  ROOF_MATERIAL: 'RoofMaterial',
  ROOF_SHAPE: 'RoofShape',
  CONSTRUCTION: 'Construction',
};

export const PAGES = {
  CondoStoriesPage: 'CondoStoriesPage',
  CondoUnitsPage: 'CondoUnitsPage',
  YearBuiltPage: 'YearBuiltPage',
  RoofMaterialPage: 'RoofMaterialPage',
  RoofMaterialFollowUpPage: 'RoofMaterialFollowUpPage',
  RoofShapePage: 'RoofShapePage',
  BuildingMaterialPage: 'BuildingMaterialPage',
  InitialQuotePage: 'InitialQuotePage',
  CustomerInformationPage: 'CustomerInformationPage',
  PasswordlessPage: 'PasswordlessPage',
  PreInterviewLoadingPage: 'PreInterviewLoadingPage',
  EffectiveDatePage: 'EffectiveDatePage',
  WaterHeaterPage: 'WaterHeaterPage',
  OccupancyPage: 'OccupancyPage',
  FinalQuestionsPage: 'FinalQuestionsPage',
  AdjustCoveragesPage: 'AdjustCoveragesPage',
  FormSelectionPage: 'FormSelectionPage',
  UniformUploadPage: 'UniformUploadPage',
  UniformQuestionnairePage: 'UniformQuestionnairePage',
  BuildingUploadPage: 'BuildingUploadPage',
  BuildingQuestionnairePage: 'BuildingQuestionnairePage',
  WindstormUploadPage: 'WindstormUploadPage',
  WindstormQuestionnairePage: 'WindstormQuestionnairePage',
  SavingsPage: 'SavingsPage',
  MortgagePage: 'MortgagePage',
  TrustPage: 'TrustPage',
  AdditionalSelectionPage: 'AdditionalSelectionPage',
  CoApplicantPage: 'CoApplicantPage',
  AdditionalInsuredPage: 'AdditionalInsuredPage',
  MailingAddressPage: 'MailingAddressPage',
  ReviewPage: 'ReviewPage',
  PaymentPage: 'PaymentPage',
  CompletionPage: 'CompletionPage',
};

export const ERROR_TYPES = {
  REFERRED: 'Referred',
  DECLINED_LOCATION_UNAVAILABLE: 'DeclinedLocationUnavailable',
  DECLINED_LOCATION_BLOCKED: 'DeclinedLocationBlocked',
  GENERIC_DECLINE: 'Declined',
  ISSUANCE_FAILURE: 'IssuanceFailure',
};

export const COLLECTION_METHOD = {
  RECURRING: 'EFTC',
  ADHOC: 'Paper',
};

export const LOADING_MESSAGE = {
  ACCOUNT_CREATION: 'Thank you! One moment while we get your account set up...',
  RESUMPTION: 'Welcome back! One sec while we access your quote-in-progress...',
  PAYMENT_PREFETCH: 'One moment while we fetch your payment options...',
  PAYMENT_PROCESSING: 'Just a moment while your payment finishes processing.',
};
