import platform from 'platform';

import config from 'src/config';

let analytics;
let context = {
  os: {
    name: platform?.os?.family,
    version: platform?.os?.version,
  },
};

if (!config.segment.apiKey) {
  analytics = window.analytics = {
    identify: () => {},
    page: () => {},
    track: () => {},
    reset: () => {},
  };
} else {
  const defaultOptions = {
    label: null,
    callback: () => {},
  };
  analytics = {
    identify: (userId, traits, options, callback) =>
      window.analytics.identify(
        userId,
        traits,
        { ...options, context },
        callback
      ),
    page: (name, properties = {}, options) =>
      window.analytics.page(name, properties, { ...options, context }),
    track: ({ name, category }, quote, options = defaultOptions) => {
      const label = options?.label ?? defaultOptions.label;
      const callback = options?.callback ?? defaultOptions.callback;

      const properties = {
        category,
        ...(quote?.Property && {
          'risk state': quote.Property.Location.State,
          'risk zip': quote.Property.Location.ZipCode,
          'product code': quote.FormType,
          'uw company': 'FSIC',
        }),
      };

      if (label) {
        properties.label = label;
      }

      if (quote?.quoteId) {
        properties['quote id'] = quote.quoteId;
      }

      if (quote?.Premium) {
        properties.value = quote.Premium;
      }

      window.analytics.track(name, properties, { context }, callback);
    },
    reset: (...args) => window.analytics.reset(...args),
  };
}

export default analytics;

export const CATEGORIES = {
  ERROR: 'error',
  EXPLORE_PRICING: 'explore pricing',
  PROCEED_WITH_QUOTE: 'proceed with quote',
  INTERVIEW: 'interview',
  COVERAGE: 'coverage',
  REVIEW_AND_PAY: 'review and pay',
  WRAP_UP: 'wrap up',
  RESUMPTION: 'resumption',
  BLOCKED: 'blocked',
  DOWNLOADS: 'downloads',
};

export const EVENTS = {
  SIGNUP_FOR_PRODUCT_ALERTS: {
    name: 'signup for product alerts',
    category: CATEGORIES.EXPLORE_PRICING,
  },
  UNEXPECTED_ERROR: { name: 'unexpected error', category: CATEGORIES.ERROR },
  STARTED_QUOTE: {
    name: 'started quote',
    category: CATEGORIES.EXPLORE_PRICING,
  },
  VIEWED_ESTIMATED_PREMIUM: {
    name: 'viewed estimated premium',
    category: CATEGORIES.EXPLORE_PRICING,
  },
  MISSING_INFO: {
    name: 'missing info',
    category: CATEGORIES.EXPLORE_PRICING,
  },
  PROCEEDED_WITH_QUOTE: {
    name: 'proceeded with quote',
    category: CATEGORIES.PROCEED_WITH_QUOTE,
  },
  COMPLETED_CUSTOMER_INFO: {
    name: 'completed customer info',
    category: CATEGORIES.PROCEED_WITH_QUOTE,
  },
  COMPLETED_INTERVIEW: {
    name: 'completed interview',
    category: CATEGORIES.INTERVIEW,
  },
  UPDATED_COVERAGE: {
    name: 'updated coverage',
    category: CATEGORIES.COVERAGE,
  },
  COMPLETED_COVERAGE: {
    name: 'completed coverage',
    category: CATEGORIES.COVERAGE,
  },
  STARTED_WIND_MIT: {
    name: 'started wind mit',
    category: CATEGORIES.COVERAGE,
  },
  COMPLETED_WIND_MIT: {
    name: 'completed wind mit',
    category: CATEGORIES.COVERAGE,
  },
  PROCEEDED_TO_PURCHASE: {
    name: 'proceeded to purchase',
    category: CATEGORIES.REVIEW_AND_PAY,
  },
  SUBMITTED_PAYMENT: {
    name: 'submitted payment',
    category: CATEGORIES.REVIEW_AND_PAY,
  },
  POLICY_ISSUED: {
    name: 'policy issued',
    category: CATEGORIES.WRAP_UP,
  },
  FOLLOWED_ESIG_LINK: {
    name: 'followed esig link',
    category: CATEGORIES.WRAP_UP,
  },
  DOWNLOADED_FILE: {
    name: 'downloaded file',
    category: CATEGORIES.DOWNLOADS,
  },
  RESUMED_QUOTE: {
    name: 'resumed quote',
    category: CATEGORIES.RESUMPTION,
  },
  QUOTE_DECLINED: {
    name: 'quote declined',
    category: CATEGORIES.BLOCKED,
  },
  QUOTE_REFERRED: {
    name: 'quote referred',
    category: CATEGORIES.BLOCKED,
  },
  BINDING_RESTRICTION: {
    name: 'binding restriction',
    category: CATEGORIES.BLOCKED,
  },
};
