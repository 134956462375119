import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { ERROR_TYPES } from 'src/products/florida/ho6/constants';
import analytics, { EVENTS } from 'src/lib/analytics';
import ErrorDecline from './Types/Generic';
import IssuanceFailure from './Types/IssuanceFailure';
import UnknownError from './Types/UnknownError';
import DeclinedLocation from './Types/DeclinedLocation';
import AgentReferral from './Types/AgentReferral';

const switchEvent = (type) => {
  switch (type) {
    case ERROR_TYPES.DECLINED_LOCATION_UNAVAILABLE:
    case ERROR_TYPES.DECLINED_LOCATION_BLOCKED:
    case ERROR_TYPES.GENERIC_DECLINE:
      return EVENTS.QUOTE_DECLINED;
    case ERROR_TYPES.REFERRED:
      return EVENTS.QUOTE_REFERRED;
    default:
      return EVENTS.UNEXPECTED_ERROR;
  }
};

const switchType = (type) => {
  switch (type) {
    case ERROR_TYPES.DECLINED_LOCATION_UNAVAILABLE:
    case ERROR_TYPES.DECLINED_LOCATION_BLOCKED:
      return DeclinedLocation;
    case ERROR_TYPES.REFERRED:
      return AgentReferral;
    case ERROR_TYPES.ISSUANCE_FAILURE:
      return IssuanceFailure;
    case ERROR_TYPES.GENERIC_DECLINE:
      return ErrorDecline;
    default:
      return UnknownError;
  }
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    const errorType = error?.response?.data?.context?.errorType;

    analytics.track(switchEvent(errorType), error.quote ?? this.props.quote, {
      label:
        errorType ?? error?.response?.status ?? error?.statusCode ?? 'unknown',
    });
  }

  handleStartOver = () => {
    this.setState({ error: null });
    this.props.signOut();
    this.props.history.replace('/');
  };

  render() {
    const { error } = this.state;

    if (!!error) {
      const ErrorDecline = switchType(
        error?.response?.data?.context?.errorType
      );

      return (
        <ErrorDecline
          quote={error.quote ?? this.props.quote}
          {...error?.response?.data?.context}
          handleStartOver={this.handleStartOver}
          declinationNotice={error?.response?.data?.declinationNotice}
        />
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = ({ quote }) => ({ quote });
const mapDispatchToProps = ({ user: { signOut } }) => ({ signOut });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ErrorBoundary));
