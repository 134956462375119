import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { BasicLayout, Card, Footer } from 'src/common';
import analytics from 'src/lib/analytics';

const AccountUnblocked = () => {
  const history = useHistory();

  useEffect(() => {
    analytics.page('AccountUnblocked');
  }, []);

  const handleResume = () => {
    history.replace('/resume');
  };

  return (
    <BasicLayout displayNavElements={false}>
      <Card
        icon="padlock"
        title="Account unlocked."
        subtitle="Thanks for telling us this was you!"
      />
      <Footer displayAssitance={false}>
        <Footer.Next rounded label="Back to my Quote" onClick={handleResume} />
      </Footer>
    </BasicLayout>
  );
};

export default AccountUnblocked;
