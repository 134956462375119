import { useRef, useEffect } from 'react';

const useMicroformListener = (event, handler, field) => {
  const handlerRef = useRef();

  useEffect(() => {
    handlerRef.current = handler;
  }, [event, handler]);

  useEffect(() => {
    if (!field) {
      return () => {};
    }

    const listener = (e) => handlerRef.current(e);

    field.on(event, listener);

    return () => {
      field.off(event, listener);
    };
  }, [event, field]);
};

export default useMicroformListener;
