import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

import Input from 'src/common/Input';

const noop = (v) => v;

const MaskedInput = ({
  name,
  control,
  rules,
  mask,
  defaultValue,
  transform,
  ...props
}) => {
  const { field } = useController({ name, rules, control, defaultValue });
  const transformIn = transform?.input ?? noop;
  const transformOut = transform?.output ?? noop;

  rules.setValueAs = transformOut;

  useEffect(() => {
    field.onChange(transformIn(field.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReactInputMask mask={mask} value={field.value} onChange={field.onChange}>
      {(maskedInputProps) => (
        <Input {...maskedInputProps} ref={field.ref} name={name} {...props} />
      )}
    </ReactInputMask>
  );
};

export default MaskedInput;
