import { useFlags } from 'launchdarkly-react-client-sdk';

const useFeatureFlags = () => {
  const flags = useFlags();

  if (process.env.NODE_ENV !== 'production') {
    try {
      /* NOTE: require is needed to avoid dev only code from showing up in the production build */
      return { ...flags, ...require('src/flags.json') };
    } catch {
      return flags;
    }
  }

  return flags;
};

export default useFeatureFlags;
