import { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
import FocusTrap from 'focus-trap-react';
import { classnames } from 'tailwindcss-classnames';
import dayjs from 'dayjs';

import { useAuth0, useModel } from 'src/lib/hooks';
import { Icon } from 'src/common';

const TooltipPortal = ({ children }) =>
  ReactDOM.createPortal(children, document.querySelector('body'));

const NavLink = ({ label, icon, href, disabled, ...props }) =>
  !disabled ? (
    <a
      href={href}
      onClick={() => {}}
      className="text-daintree flex items-center font-bold"
      {...props}
    >
      <Icon name={icon} size="sm" className="mr-2" />
      {label}
    </a>
  ) : (
    <p className="text-daintree flex items-center font-bold opacity-50 cursor-not-allowed">
      <Icon name={icon} size="sm" className="mr-2" />
      {label}
    </p>
  );

const NavActions = ({ displayEffectiveDate }) => {
  const { logout, user } = useAuth0();
  const btnRef = useRef(null);
  const tooltipRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { EffectiveDate } = useModel.quote();

  const policyDate = `Policy Date: ${dayjs(EffectiveDate).format(
    'MM/DD/YYYY'
  )}`;

  const handleSignOut = (e) => {
    e.preventDefault();
    logout('/pause');
  };

  const handleStartOver = (e) => {
    e.preventDefault();
    logout();
  };

  const handleOpen = (open) => {
    setOpen(open);
  };

  const handleClose = () => {
    if (tooltipRef && tooltipRef.current) {
      // Why this? https://github.com/wwayne/react-tooltip/issues/449
      tooltipRef.current.tooltipRef = null;
    }
    ReactTooltip.hide();

    if (btnRef && btnRef.current) {
      btnRef.current.focus();
    }
  };

  useEffect(() => {
    const onKeyup = (e) => {
      if (open && e.key === 'Escape') {
        handleClose();
      }
    };
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [open]);

  return (
    <div>
      <button
        type="button"
        data-event="click"
        data-for="header-tooltip"
        data-tip="header-tooltip"
        aria-label="open navigation menu"
        aria-controls="header-tooltip"
        ref={btnRef}
        aria-expanded={open ? true : false}
      >
        <Icon name="bars" color="daintree" size="lg" />
      </button>
      <TooltipPortal>
        <FocusTrap active={open}>
          <div>
            <ReactTooltip
              id="header-tooltip"
              place="bottom"
              effect="solid"
              event="click"
              arrowColor="transparent"
              borderCOlor="transparent"
              multiline
              clickable
              afterShow={() => handleOpen(true)}
              afterHide={() => handleOpen(false)}
              ref={tooltipRef}
              role="dialog"
              aria-modal="true"
              offset={{ left: 75 }}
            >
              <div className="w-44 p-6 space-y-3 rounded-lg bg-white">
                <NavLink
                  label="Sign Out"
                  icon="exit"
                  href="/pause"
                  onClick={handleSignOut}
                  disabled={!user?.hasSession}
                />
                <NavLink
                  label="Start Over"
                  icon="refresh"
                  href="/"
                  onClick={handleStartOver}
                />
                <NavLink
                  label="Help"
                  icon="help"
                  href="https://skyway.com/help"
                  target="_blank"
                />
                <NavLink
                  label="800-557-0964"
                  icon="phone"
                  href="tel:800-557-0964"
                />
                {EffectiveDate && displayEffectiveDate && (
                  <p className="text-xs pt-2 lg:hidden">{policyDate}</p>
                )}
              </div>
            </ReactTooltip>
            <button
              tabIndex={-1}
              aria-hidden="true"
              onClick={handleClose}
              className={classnames(
                'fixed inset-0 w-screen h-screen z-40 transition outline-none cursor-default',
                {
                  'visible': open,
                  'invisible ': !open,
                }
              )}
            />
          </div>
        </FocusTrap>
      </TooltipPortal>
    </div>
  );
};

export default NavActions;
