import React from 'react';
import classNames from 'tailwindcss-classnames';

const Label = ({ srOnly, ...props }) => (
  <label
    className={
      srOnly ? classNames('sr-only', props.className) : props.className
    }
    {...props}
  >
    {props.children}
  </label>
);

Label.defaultProps = {
  srOnly: false,
};

export default Label;
