import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import analytics, { EVENTS } from 'src/lib/analytics';
import { useAuth0, useModel } from '.';

const handler = async (err, logout, reset, setException, quote) => {
  const statusCode = err?.statusCode ?? err?.response?.status;

  if ([500, 503, 504, 408, 429].includes(statusCode)) {
    analytics.track(EVENTS.UNEXPECTED_ERROR, err.quote ?? quote, {
      label: statusCode,
    });
    return 'We ran into an issue. Please try again in a few minutes.';
  } else if (statusCode === 401) {
    await logout('/resume');
    return 'Something went wrong. Please try again later.';
  } else if ([404, 422].includes(statusCode)) {
    err.quote = err.quote ?? quote;
    setException(err);
    reset();
  } else {
    analytics.track(EVENTS.UNEXPECTED_ERROR, err.quote ?? quote, {
      label: statusCode ?? 'unknown',
    });
    return (
      err?.response?.data?.context?.description ??
      'Something went wrong. Please try again later.'
    );
  }
};

const useErrorHandling = () => {
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const quote = useModel.quote();
  const [exception, setException] = useState(null);

  const reset = () => {
    dispatch({ type: 'global/reset' });
  };

  useEffect(() => {
    if (exception) {
      throw exception;
    }
  }, [exception]);

  const handleError = (err) => handler(err, logout, reset, setException, quote);

  return handleError;
};

export default useErrorHandling;
