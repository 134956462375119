import React, { useCallback, useState } from 'react';

import { BasicLayout, Card, Footer, Input, Form, Error } from 'src/common';
import { useAuth0, useForm } from 'src/lib/hooks';

const Verify = ({ layoutProps = {}, ...props }) => {
  const {
    user,
    verifyCode,
    handleVerifyCodeError,
    startLogin,
    handleStartLoginError,
    logout,
  } = useAuth0();
  const [sending, setSending] = useState(false);
  const { register, handleSubmit, errors, formState, formError, setFormError } =
    useForm({
      onError: async ({ err, setFormError, defaultOnError }) => {
        if (err?.error) {
          setFormError(handleVerifyCodeError(err));
        } else {
          await defaultOnError();
        }
      },
    });

  const onBack = () => {
    if (props.onBack) {
      props.onBack();
      return;
    }

    logout();
  };

  const onSubmit = handleSubmit(async (data) => {
    await verifyCode(data.Code);
  });

  const resendCode = useCallback(async () => {
    setSending(true);
    try {
      await startLogin(user.email);
    } catch (err) {
      setFormError(handleStartLoginError(err));
    } finally {
      setSending(false);
    }
  }, [user.email, startLogin, handleStartLoginError, setFormError]);

  return (
    <BasicLayout {...layoutProps}>
      <Card
        icon="codeSafe"
        title="Enter your code."
        subtitle="Check your email for the six-digit verification code we just sent you."
      >
        {formError && <Error>{formError}</Error>}
        <Form id="verification-code-form" onSubmit={onSubmit}>
          <Input
            label="Verification Code"
            name="Code"
            error={errors?.Code?.message}
            ref={register({
              required: 'Verification code is required.',
            })}
            containerClassName="max-w-xs mx-auto"
            autocomplete="one-time-code"
          />
        </Form>
        {user && (
          <div className="mt-4 text-center text-sm">
            Didn't receive the code?
            <button
              className="text-curious-blue-dark pl-1 disabled:text-dove-gray disabled:cursor-not-allowed underline"
              onClick={resendCode}
              disabled={formState.isSubmitting || sending}
            >
              {sending ? ' Sending...' : ' Resend!'}
            </button>
          </div>
        )}
      </Card>
      <Footer>
        <Footer.Next
          onClick={onSubmit}
          loading={formState.isSubmitting}
          label="Submit"
          icon="check"
          color="blue"
        />
        <Footer.Back onClick={onBack} disabled={formState.isSubmitting} />
      </Footer>
    </BasicLayout>
  );
};

export default Verify;
