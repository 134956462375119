import { BasicLayout, Button, Card } from 'src/common';
import { PROGRESS } from 'src/products/florida/ho6/constants';

const RetryPage = ({ submit }) => {
  return (
    <BasicLayout displayEffectiveDate={false}>
      <Card
        displayEffectiveDate={false}
        progressBar={PROGRESS.PROCEED}
        icon="seashells"
        title="Aw, shucks!"
        subtitle="Something went wrong."
      >
        <div className="flex justify-center">
          <Button
            color="dark"
            variant="rectangular"
            className="w-36 justify-center"
            onClick={submit}
          >
            Try Again
          </Button>
        </div>
      </Card>
    </BasicLayout>
  );
};
export default RetryPage;
