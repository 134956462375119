import merge from 'lodash.merge';
import axios from 'axios';
import createHash from 'create-hash';

import config from 'src/config';
import analytics, { EVENTS } from 'src/lib/analytics';
import { api } from 'src/lib/hooks/useApi/useApi';
import { windMitTransform } from 'src/lib/transforms';

export const generateQuoteId = (ID) =>
  createHash('sha256')
    .update(ID + config.auth0.domain)
    .digest('base64');

const quote = {
  state: {},
  reducers: {
    'setQuote': (state, updatedQuote) => merge({}, state, updatedQuote),
    'global/reset': (state) => ({
      ID: state.ID,
      FirstName: state.FirstName,
      LastName: state.LastName,
      Property: state.Property,
    }),
  },
  effects: (dispatch) => ({
    resume: async ({ ID, PolicyNumber, ResumeFrom }) => {
      const { data } = await api.secure.get(`/quote/${ID}`);
      const { FirstName, LastName } = data.Customer;

      const WindMitigationFormData = windMitTransform.input(
        data.WindMitigationFormData
      );

      if (PolicyNumber) {
        dispatch.session.setSession({
          policy: {
            policyNumber: PolicyNumber,
          },
        });
      }

      const quote = {
        ...data,
        WindMitigationFormData,
        FirstName,
        LastName,
        State: 'florida',
        Product: 'ho6',
        ID,
        quoteId: generateQuoteId(ID),
      };
      dispatch.quote.setQuote(quote);

      analytics.track(EVENTS.RESUMED_QUOTE, quote, { label: ResumeFrom });
    },
    newQuote: async (details) => {
      const State = 'florida';
      const Product = 'ho6';

      const initialQuote = {
        State,
        Product,
        ...details,
      };
      dispatch.quote.setQuote(initialQuote);

      try {
        const { data } = await api.post('/quote', details);

        const { ID, Premium, MissingInfo, AssociationId } = data;

        const quote = {
          ID,
          quoteId: generateQuoteId(ID),
          Premium,
          AssociationId,
          State,
          Product,
          MissingInfo,
          ...details,
        };
        dispatch.quote.setQuote(quote);

        analytics.track(EVENTS.STARTED_QUOTE, quote);
      } catch (err) {
        err.quote = initialQuote;
        throw err;
      }
    },
    updateQuote: async (updates, root) => {
      const { ID } = root.quote;

      const { data: currentQuote } = await api.secure.patch(
        `/quote/${ID}`,
        updates
      );

      const WindMitigationFormData = windMitTransform.input(
        currentQuote.WindMitigationFormData
      );

      dispatch.quote.setQuote({ ...currentQuote, ...WindMitigationFormData });
    },
    checkEligibility: async (_, root) => {
      const { ID } = root.quote;

      const {
        data: { Premium },
      } = await api.secure.patch(`/quote/${ID}/eligibility`);

      dispatch.quote.setQuote({ Premium });
    },
    claimQuote: async (_, root) => {
      const { ID, Property, AssociationId } = root.quote;

      if (AssociationId) {
        await api.secure.put(`/quote/${ID}/association/${AssociationId}`);

        await api.secure.put(`/profiles/quotes/${ID}`, {
          Location: Property.Location,
        });

        dispatch.quote.setQuote({ AssociationId: null });
      }
    },
    fetchQuote: async (_, root) => {
      const { ID } = root.quote;
      const { data } = await api.secure.get(`/quote/${ID}`);

      const WindMitigationFormData = windMitTransform.input(
        data.WindMitigationFormData
      );

      const quote = {
        ...data,
        WindMitigationFormData,
      };
      dispatch.quote.setQuote(quote);

      return quote;
    },
    updateCoveragesAndDeductibles: async (quote, root) => {
      const { ID } = root.quote;

      const { data } = await api.secure.patch(`/quote/${ID}`, {
        Coverages: quote.Coverages,
        Deductibles: quote.Deductibles,
      });

      const { Premium, Deductibles, Coverages } = data;

      dispatch.quote.setQuote({
        Premium,
        Coverages,
        Deductibles,
      });
    },
    upsertMissingInfo: async ({ MissingInfo, Interview }, root) => {
      const { ID: QuoteNumber, AssociationId } = root.quote;
      const {
        data: { Premium },
      } = await api.patch('/quote/missing-info', {
        QuoteNumber,
        AssociationId,
        MissingInfo,
        Interview,
      });

      dispatch.quote.setQuote({ Premium });
    },
    upsertWindMitQuestionnaire: async ({ FormType, Questionnaire }, root) => {
      const { ID } = root.quote;
      const WindMitigationFormData = { ...root.quote.WindMitigationFormData };
      WindMitigationFormData[FormType] = {
        ...WindMitigationFormData[FormType],
        Questionnaire,
      };

      const body = windMitTransform.output(WindMitigationFormData);

      const {
        data: { TotalDiscounts },
      } = await api.secure.put(`/quote/${ID}/wind-mitigation`, body);

      dispatch.quote.setQuote({
        WindMitigationFormData,
        TotalDiscounts,
      });
    },
    uploadWindMitForm: async ({ FormType, InspectionDate, File }, root) => {
      const { ID } = root.quote;

      const WindMitigationFormData = { ...root.quote.WindMitigationFormData };
      WindMitigationFormData[FormType] = {
        ...WindMitigationFormData[FormType],
        Form: { FormType, InspectionDate },
      };

      if (File) {
        WindMitigationFormData[FormType].Form.FileName = File.name;
        const { data } = await api.secure.post(
          `/quote/${ID}/wind-mitigation/${FormType}`,
          {
            FileName: File.name,
            InspectionDate,
          }
        );

        await axios.put(data.FileUrl, File, {
          headers: {
            'Content-Type': 'application/octet-stream',
            'x-ms-blob-type': 'BlockBlob',
          },
        });
      }

      dispatch.quote.setQuote({ WindMitigationFormData });
    },
    upsertTrust: async (payload, root) => {
      const { ID } = root.quote;

      const { data } = await api.secure.put(
        `/quote/${ID}/trust-trustee`,
        payload
      );

      dispatch.quote.setQuote({ ...root.quote, ...data });
    },
    upsertCoApplicant: async (coapplicant, root) => {
      const { ID } = root.quote;
      const { data: currentQuote } = await api.secure.patch(`/quote/${ID}`, {
        CoApplicant: coapplicant,
      });

      dispatch.quote.setQuote(currentQuote);
    },
    excludeCoApplicant: async (_, root) => {
      const { ID } = root.quote;
      const { data: currentQuote } = await api.secure.patch(`/quote/${ID}`, {
        CoApplicant: {
          FirstName: null,
          LastName: null,
          Phone: null,
          Email: null,
          DateOfBirth: null,
        },
      });

      dispatch.quote.setQuote(currentQuote);
    },
    upsertInsured: async (payload, root) => {
      const { ID } = root.quote;
      const { data } = await api.secure.put(
        `/quote/${ID}/additional-insured`,
        payload
      );

      dispatch.quote.setQuote({
        ...root.quote,
        AdditionalInsured: data,
      });
    },
    excludeInsured: async (_, root) => {
      const { ID } = root.quote;
      const AdditionalInsured = root.quote?.AdditionalInsured;

      if (AdditionalInsured) {
        await api.secure.delete(`/quote/${ID}/additional-insured`);
        dispatch.quote.setQuote({ AdditionalInsured: null });
      }
    },
    upsertMortgagee: async (body, root) => {
      const { ID } = root.quote;
      const { data: Mortgagee } = await api.secure.put(
        `/quote/${ID}/mortgagee`,
        body
      );

      dispatch.quote.setQuote({
        Mortgagee,
      });
    },
    excludeTrustTrustee: async (_, root) => {
      const { ID } = root.quote;
      const Trust = root.quote?.Trust;
      const Trustee = root.quote?.Trustee;

      if (Trust && Trustee) {
        await api.secure.delete(`/quote/${ID}/trust-trustee`);

        dispatch.quote.setQuote({ Trust: null, Trustee: null });
      }
    },
    excludeMortgagee: async (_, root) => {
      const { ID } = root.quote;
      const Mortgagee = root.quote?.Mortgagee;

      if (!!Mortgagee) {
        await api.secure.delete(`/quote/${ID}/mortgagee`);

        dispatch.quote.setQuote({ Mortgagee: null });
      }
    },
    excludeWindMitForms: async ({ FormType }, root) => {
      const { ID } = root.quote;
      const WindMitigationFormData = { ...root.quote.WindMitigationFormData };

      if (WindMitigationFormData[FormType]) {
        WindMitigationFormData[FormType] = null;
        const body = windMitTransform.output(WindMitigationFormData);

        await api.secure.put(`/quote/${ID}/wind-mitigation`, body);

        dispatch.quote.setQuote({ WindMitigationFormData });
      }
    },
    makePaymentAndIssue: async (body, { quote }) => {
      const { ID } = quote;
      const { data } = await api.secure.post(
        `/quote/${ID}/pay-and-issue`,
        body
      );

      dispatch.session.setSession({
        policy: {
          policyNumber: data.policyNumber,
          confirmationNumber: data.data.clientReferenceInformation.code,
          eSignatureLink: data.eSignatureLink,
        },
      });

      analytics.track(EVENTS.POLICY_ISSUED, quote);

      await dispatch.quote.fetchQuote();
    },
    getPaymentPlans: async (body, { quote: { ID } }) => {
      const { data } = await api.secure.get(`/quote/${ID}/payment-plan`, body);

      dispatch.quote.setQuote({
        PaymentPlans: {
          Available: data.AvailablePlans,
          Selected: data.SelectedPlan,
        },
      });
    },
    downloadAttachment: async ({ ID, attachment }) => {
      const file = await api.secure.get(
        `/quote/${ID}/attachments/${attachment.Id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
          },
          responseType: 'blob',
        }
      );
      const blob = new Blob([file.data], { type: 'application/pdf' });

      // Old MS Edge don't allow using a blob object directly as link href
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
      } else {
        const objUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objUrl;
        link.download = `${attachment.Title}.pdf`;
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => {
          window.URL.revokeObjectURL(objUrl);
        }, 250);
      }
    },
  }),
};

export default quote;
