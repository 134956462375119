import { useEffect } from 'react';

import { useModel } from 'src/lib/hooks';
import { BasicLayout, Card } from 'src/common';
import analytics from 'src/lib/analytics';

const Maintenance = () => {
  const { Property } = useModel.quote();

  useEffect(() => {
    analytics.page('Maintenance');
  }, []);

  return (
    <BasicLayout displayNavElements={false}>
      <Card
        icon="toolkit"
        title="Our apologies."
        contentClassName="max-w-xl"
        subtitle={
          <>
            {!!Property?.Location
              ? 'Our systems started their routine maintenance service while you were working on that question, so your answers got lost at sea.'
              : 'Our systems’ routine maintenance service is underway.'}
            <strong className="block my-6">Please try again later.</strong>
            Sorry about the inconvenience!
          </>
        }
      />
    </BasicLayout>
  );
};

export default Maintenance;
