import dayjs from 'dayjs';
import isemail from 'isemail';

const dateFormat = 'MM/DD/YYYY';
const validEmailRegex = new RegExp(
  '^[\\w\\d\\.\\-\\_\\+]+@[\\w\\d\\.\\-\\_\\+]+$'
);

export const parseDate = (value) => {
  const date = dayjs(value);

  if (!date.isValid()) {
    return null;
  }

  return date.format('MM/DD/YYYY') === value ||
    date.format('YYYY-MM-DD') === value
    ? date
    : null;
};

export const validateBirthDate = (value, minAge = 15) => {
  const date = parseDate(value);

  if (date === null) {
    return 'Invalid date.';
  }

  let year = value.substr(value.length - 4, value.length);
  if (year < 1000) {
    return 'Invalid date.';
  }

  const minDate = dayjs('01/01/1900', dateFormat);
  const maxDate = dayjs().subtract(minAge, 'year');

  const tooYoung = maxDate.diff(date, 'day', true) < 0;
  const tooOld = minDate.diff(date, 'day', true) > 0;

  if (tooYoung || tooOld) {
    return `Must be between ${minDate.format(dateFormat)}
    and ${maxDate.format(dateFormat)}.`;
  }

  return true;
};

export const validateYear = (year) => {
  let currentYear = dayjs().year();
  if (year <= 0 || !dayjs(year, 'YYYY').isValid()) {
    return 'Must be a valid year.';
  }
  if (year < 1900 || year > currentYear) {
    return `Must be between 1900 and ${currentYear}.`;
  }
  return true;
};

export const validateEffectiveDate = (value) => {
  const currentDate = dayjs().startOf('day');
  const date = parseDate(value);

  if (date === null) {
    return 'Invalid date.';
  }

  const tooSoon = date.diff(currentDate, 'day', true) < 0;
  const tooLate = date.diff(currentDate, 'day', true) > 60;

  if (tooSoon || tooLate) {
    return 'Must be within the next 60 days.';
  }

  const minDay = dayjs('09/01/2021', dateFormat, true);

  if (minDay.isAfter(value)) {
    return 'Must be on or after 9/1/2021.';
  }

  return true;
};

export const validateInspectionDate = (value) => {
  const currentDate = dayjs();
  const date = parseDate(value);

  if (date === null) {
    return 'Invalid date.';
  }

  const tooSoon = date.diff(currentDate, 'year', true) < -5;
  const tooLate = date.diff(currentDate, 'day', true) > 0;

  if (tooSoon || tooLate) {
    return 'Must be within the last 5 years.';
  }

  return true;
};

export const validateEmail = (value) => {
  if (!isemail.validate(value)) {
    return 'Invalid email.';
  }

  if (!validEmailRegex.test(value)) {
    return 'Invalid email.';
  }

  return true;
};
