import React, { useState } from 'react';

import {
  BasicLayout,
  Card,
  Footer,
  Button,
  Form,
  Input,
  Error,
} from 'src/common';
import { useForm, useModel } from 'src/lib/hooks';
import analytics, { EVENTS } from 'src/lib/analytics';
import { validateEmail } from 'src/lib/validations';
import config from 'src/config';

const DeclinedLocation = ({
  quote,
  title = 'Uh oh :(',
  description = 'We do not offer policies for your area… yet.',
  handleStartOver,
}) => {
  const { FirstName, LastName } = useModel.quote();
  const { register, handleSubmit, errors, formError } = useForm();
  const [form, setForm] = useState(true);

  const onSubmit = handleSubmit(async (data) => {
    if (!!config.segment.apiKey) {
      await new Promise((resolve, reject) => {
        try {
          analytics.identify(
            data.Email,
            {
              email: data.Email,
              name: `${FirstName} ${LastName}`,
            },
            {},
            () =>
              analytics.track(EVENTS.SIGNUP_FOR_PRODUCT_ALERTS, quote, {
                callback: () => {
                  analytics.reset();
                  resolve();
                },
              })
          );
        } catch {
          reject();
        }
      });
    }

    setForm(false);
  });

  return (
    <BasicLayout displayNavElements={false}>
      <Card icon="palm" title={title}>
        <h2 className="text-center">
          {`${description} `}
          <br />
          <a
            href="https://skyway.com/horizon"
            className="text-curious-blue-dark underline"
            target="_blank"
            rel="noreferrer"
          >
            Learn more.
          </a>
        </h2>
        <h2 className="text-center font-bold mt-5">
          Sign up to be notified when we expand our offerings:
        </h2>

        {formError && <Error>{formError}</Error>}
        {form ? (
          <Form id="email-form" onSubmit={onSubmit}>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 space-x-0 sm:space-x-4 mt-5 justify-center items-center sm:items-start">
              <Input
                containerClassName="w-full sm:w-72"
                label="Email"
                name="Email"
                error={errors?.Email?.message}
                placeholder="name@domain.com"
                ref={register({
                  required: { value: true, message: 'Email is required.' },
                  validate: validateEmail,
                })}
              />
              <Button
                className="flex w-max"
                onClick={onSubmit}
                color="dark"
                variant="rectangular"
              >
                Sign up
              </Button>
            </div>
          </Form>
        ) : (
          <h2 className="text-center mt-5">
            <b>Got it! </b> We'll be in touch.
          </h2>
        )}
      </Card>
      <Footer>
        <Footer.Next
          rounded
          label="Exit"
          onClick={handleStartOver}
          icon={null}
        />
      </Footer>
    </BasicLayout>
  );
};

export default DeclinedLocation;
