import dayjs from 'dayjs';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const identityTransform = {
  input: (v) => v,
  output: (v) => v,
};

export const currencyTransform = {
  input: (v) =>
    v || v === 0 ? formatter.format(v).replace(/\D00(?=\D*$)/, '') : null,
  output: (v) => v && Number(v.replace(/\$|,/g, '')),
};

export const coverageTransform = {
  DOLLARS: {
    input: ({ Value }) => Value,
    output: (v) => ({
      Type: 'DOLLARS',
      Value: v,
    }),
  },
};

export const dateTransform = {
  input: (v) => v && dayjs(v).format('MM/DD/YYYY'),
  output: (v) => {
    const date = dayjs(v);

    return v && date.isValid() ? date.format('YYYY-MM-DD') : null;
  },
};

export const windMitTransform = {
  input: (data) =>
    Object.entries(data ?? {}).reduce((agg, [key, value]) => {
      const { FormType, FileName, InspectionDate, ...Questionnaire } = value;
      return {
        ...agg,
        [key]: {
          Form: {
            FormType,
            FileName,
            InspectionDate,
          },
          Questionnaire,
        },
      };
    }, {}),
  output: (data) =>
    Object.entries(data).reduce(
      (agg, [key, value]) =>
        value?.Questionnaire
          ? { ...agg, [key]: { ...value.Form, ...value.Questionnaire } }
          : agg,
      {}
    ),
};

export const phoneNumberTransform = {
  transform: (v) => {
    let phoneNumber =
      v && v.replace(/\D/g, '').match(/^(\d{3})(\d{3})(\d{4})$/);
    return `${phoneNumber[1]}-${phoneNumber[2]}-${phoneNumber[3]}`;
  },
};
