import React, { useRef, useEffect, useCallback } from 'react';
import Card from './Card';

const Modal = ({ children, ariaLabel = 'modal', ...cardProps }) => {
  const modalRef = useRef(null);
  const cardRef = useRef(null);

  const handleTabKey = useCallback((e) => {
    if (modalRef.current) {
      const focusableModalElements = getFocusableElements();
      const firstElement = focusableModalElements[0];
      const lastElement = focusableModalElements[
        focusableModalElements.length - 1
      ].disabled
        ? focusableModalElements[focusableModalElements.length - 2]
        : focusableModalElements[focusableModalElements.length - 1];

      if (
        !Array.from(focusableModalElements).includes(document.activeElement)
      ) {
        firstElement.focus();
        return e.preventDefault();
      }
      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        return e.preventDefault();
      }

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        return e.preventDefault();
      }
    }
  }, []);

  useEffect(() => {
    if (modalRef.current) {
      cardRef.current.focus();
    }
  });

  useEffect(() => {
    const keyListener = (e) => {
      const keyListenersMap = new Map([[9, handleTabKey]]);

      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    };

    document.addEventListener('keydown', keyListener);

    return () => document.removeEventListener('keydown', keyListener);
  }, [handleTabKey]);

  const getFocusableElements = () => {
    return modalRef.current.querySelectorAll(
      'a[href], a[onClick], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select, [tabindex = "0"]'
    );
  };

  return (
    <div
      role="dialog"
      ref={modalRef}
      aria-modal="true"
      aria-label={ariaLabel}
      className="transition duration-200 ease-in absolute w-full h-full top-0 left-0 flex items-center justify-center"
    >
      <div className="absolute w-full h-full bg-spindle opacity-50 top-0 left-0"></div>
      <Card ref={cardRef} {...cardProps}>
        {children}
      </Card>
    </div>
  );
};

export default Modal;
