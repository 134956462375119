import React, { useEffect, useState } from 'react';
import { useCombobox } from 'downshift';
import axios from 'axios';
import { useController } from 'react-hook-form';
import classNames from 'tailwindcss-classnames';

import { DropdownMenu } from 'src/common';
import { useDebouncedSearch } from 'src/lib/hooks';
import config from 'src/config';
import debugData from 'src/lib/debugData';
import AptInput from './AptInput';

const expressEntryEndpoint = config.melissaData.expressEntryEndpoint;
const globalExpressEntryEndpoint =
  config.melissaData.globalExpressEntryEndpoint;
const maxrecords = 20;

const defaultTransform = {
  input: (value) =>
    value && {
      Address1: value.Address1,
      City: value.City,
      State: value.State,
      ZipCode: value.ZipCode,
      formatted: `${value.Address1} ${value.Address2}, ${value.City}, ${value.State} ${value.ZipCode}`,
      apt: null,
      SuiteName: null,
      SuiteList: [],
      SuiteCount: 0,
    },
  output: (value) =>
    value && {
      Address1: value.Address1,
      // necessary for API schema
      Address2: '',
      City: value.City,
      State: value.State,
      ZipCode: value.ZipCode,
    },
};

const AddressInput = ({
  name,
  label,
  placeholder = '',
  defaultValue = null,
  control,
  rules,
  disabled,
  error,
  containerClassName,
  column = false,
  transform,
  international,
  country,
  optional = false,
}) => {
  const { setInputText, search } = useDebouncedSearch(
    async (value) => {
      const { data } = await axios.get(
        international ? globalExpressEntryEndpoint : expressEntryEndpoint,
        {
          params: {
            id: config.melissaData.licenseKey,
            format: 'json',
            maxrecords,
            country,
            ff: value,
          },
        }
      );

      const addresses = international
        ? data.Results.map(
            ({
              Address: {
                Address1,
                Locality,
                AdministrativeArea,
                PostalCode,
                SubBuilding,
              },
            }) => {
              PostalCode =
                country === 'US' ? PostalCode.substr(0, 5) : PostalCode;

              const SuiteList = SubBuilding.split(',');
              const SuiteName =
                SuiteList.length > 1 ? SuiteList[0].split(' ')[0] : '';

              return {
                Address1,
                City: Locality,
                State: AdministrativeArea,
                ZipCode: PostalCode,
                SuiteName,
                SuiteList,
                SuiteCount: SuiteName === '' ? 0 : SuiteList.length,
                formatted: `${Address1}, ${Locality}, ${AdministrativeArea} ${PostalCode}`,
                apt: null,
              };
            }
          )
        : data.Results.map(
            ({
              Address: {
                AddressLine1,
                City,
                State,
                PostalCode,
                SuiteName: suiteName,
                SuiteList,
                SuiteCount,
              },
            }) => {
              const ZipCode = PostalCode.substr(0, 5);
              const SuiteName =
                SuiteList.length > 0 && SuiteList[0] !== ''
                  ? suiteName || SuiteList[0].split(' ')[0]
                  : '';

              return {
                Address1: AddressLine1,
                City,
                State,
                ZipCode,
                SuiteName,
                SuiteList,
                SuiteCount,
                formatted: `${AddressLine1}, ${City}, ${State} ${ZipCode}`,
                apt: null,
              };
            }
          );

      return addresses.filter(
        (addr) =>
          !addresses.find(
            (a) =>
              a.formatted === addr.formatted &&
              a.SuiteCount !== addr.SuiteCount &&
              addr.SuiteCount === 0
          )
      );
    },
    {},
    [international, country]
  );
  const [aptSearch, setAptSearch] = useState('');
  const [aptError, setAptError] = useState(null);

  const transformIn = transform?.input ?? defaultTransform.input;
  const transformOut = transform?.output ?? defaultTransform.output;

  const defaultSelectedItem = transformIn(defaultValue);

  const validate = (val) => {
    if (val?.SuiteCount > 1 && !val.apt) {
      if (aptSearch === '') {
        setAptError(`${val.SuiteName || 'Unit'} # is required.`);
      } else {
        setAptError(`${val.SuiteName || 'Unit'} # is incorrect.`);
      }
      return false;
    } else {
      setAptError(null);
      return true;
    }
  };
  const {
    field: { onChange, onBlur, ref },
  } = useController({
    name,
    control,
    rules: { setValueAs: transformOut, validate, ...rules },
    defaultValue: defaultSelectedItem,
  });

  const {
    isOpen,
    selectedItem,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
  } = useCombobox({
    items: search.result || [],
    itemToString: (item) => item?.formatted,
    onInputValueChange: (change) => {
      if (change.selectedItem?.formatted !== change.inputValue) {
        setInputText(change.inputValue);
        onChange(null);
      }
    },
    onSelectedItemChange: (change) => {
      onChange(change.selectedItem);
    },
    defaultSelectedItem,
  });

  useEffect(() => {
    if (country) {
      setInputText('');
      setInputValue('');
      onChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  const handleAptChange = (apt) => {
    const { Address1, City, State, ZipCode } = selectedItem;
    onChange({
      ...selectedItem,
      Address1: `${Address1} ${apt}`,
      formatted: `${Address1} ${apt}, ${City}, ${State} ${ZipCode}`,
      apt,
    });
  };

  return (
    <div
      className={classNames(
        'flex flex-col',
        !column && 'sm:flex-row',
        containerClassName
      )}
    >
      <div
        className={`flex flex-col relative ${
          selectedItem?.SuiteCount > 1 && !column
            ? 'w-full sm:w-4/5 pr-0 sm:pr-2'
            : 'w-full'
        }`}
      >
        {label && (
          <label
            className={classNames(
              'text-xs font-bold bg-white px-1 absolute -mt-2 ml-4',
              error ? 'text-piper' : 'text-daintree'
            )}
            {...getLabelProps()}
          >
            {label}
          </label>
        )}
        <div {...getComboboxProps()}>
          <input
            type="text"
            className={classNames(
              'w-full h-11 px-4 text-sm text-daintree border rounded-lg transition focus:ring-4',
              error
                ? 'border-piper ring-piper-light'
                : 'border-daintree ring-daintree-faded'
            )}
            {...getInputProps({
              name,
              placeholder,
              disabled,
              onBlur,
              ref,
            })}
            {...debugData({ input: 'address' })}
            autoComplete="off"
            aria-invalid={error ? 'true' : 'false'}
            {...(error && { 'aria-describedby': `${name}-error` })}
            required={!optional}
          />
          <DropdownMenu
            isOpen={isOpen}
            disabled={disabled}
            error={error}
            options={search?.result || []}
            searching={search?.loading}
            highlightedIndex={highlightedIndex}
            getMenuProps={getMenuProps}
            getItemProps={getItemProps}
            getItemValue={(item) => item.formatted}
          />
        </div>
        {error && (
          <span id={`${name}-error`} className="text-piper text-xs mt-1 ml-4">
            {error}
          </span>
        )}
      </div>
      {selectedItem?.SuiteCount > 1 && (
        <AptInput
          name={name + 'UnitNumber'}
          label={
            selectedItem.SuiteName === '#' || !selectedItem.SuiteName
              ? 'Unit #'
              : `${selectedItem.SuiteName} #`
          }
          disabled={disabled}
          aptList={selectedItem.SuiteList}
          onChange={handleAptChange}
          error={aptError}
          column={column}
          search={aptSearch}
          setSearch={setAptSearch}
          optional={optional}
        />
      )}
    </div>
  );
};

export default AddressInput;
