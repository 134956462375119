import { classnames } from 'tailwindcss-classnames';

import { Icon } from 'src/common';
import { currencyTransform } from 'src/lib/transforms';

const Premium = ({
  label = 'Annual Premium',
  premium,
  refreshing,
  needsRefresh,
  onRefresh,
  className,
  hideLabel = false,
}) => {
  const boxClasses =
    'flex flex-col justify-center items-center h-14 w-28 xs:w-40 mx-2 rounded-md';

  if (needsRefresh || refreshing) {
    return (
      <button
        onClick={onRefresh}
        className={classnames(
          'bg-curious-blue space-y-1 focus:outline-none focus:ring-2 focus:ring-mystic focus:ring-offset-2 focus:ring-offset-daintree',
          boxClasses
        )}
      >
        <Icon
          name="redo"
          color="white"
          size="md"
          className={refreshing && 'animate-spin'}
        />
        {!hideLabel && (
          <span className="text-xs text-white">Refresh Premium</span>
        )}
      </button>
    );
  }
  return (
    <div className={classnames('bg-white', boxClasses)}>
      <span className="text-xs text-daintree">{label}</span>
      <strong
        className={classnames(
          'text-lg text-daintree font-bold leading-none',
          'xs:text-2xl',
          className
        )}
      >
        {currencyTransform.input(premium)}
      </strong>
    </div>
  );
};

export default Premium;
