const config = {
  debug: process.env.REACT_APP_DEBUG,
  a11y: process.env.REACT_APP_A11Y,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENTID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  },
  skyway: {
    locked: process.env.REACT_APP_SKYWAY_LOCKED,
    baseUrl: process.env.REACT_APP_SKYWAY_BASEURL,
    clientId: process.env.REACT_APP_SKYWAY_CLIENTID,
    clientSecret: process.env.REACT_APP_SKYWAY_CLIENTSECRET,
  },
  segment: {
    apiKey: process.env.REACT_APP_SEGMENT_APIKEY,
  },
  launchDarkly: {
    clientId: process.env.REACT_APP_LAUNCHDARKLY_CLIENTID,
  },
  melissaData: {
    licenseKey: process.env.REACT_APP_MELISSADATA_LICENSEKEY,
    expressEntryEndpoint:
      process.env.REACT_APP_MELISSADATA_EXPRESSENTRYENDPOINT,
    globalExpressEntryEndpoint:
      process.env.REACT_APP_MELISSADATA_GLOBALEXPRESSENTRYENDPOINT,
  },
};

export default config;
