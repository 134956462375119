import React from 'react';
import { classnames } from 'tailwindcss-classnames';
import dayjs from 'dayjs';

import { Icon } from 'src/common';
import { useModel } from 'src/lib/hooks';

const ProgressBar = ({
  totalNotches,
  filledNotches,
  label,
  displayEffectiveDate,
}) => {
  const { EffectiveDate } = useModel.quote();

  const policyDate = `Policy Date: ${dayjs(EffectiveDate).format(
    'MM/DD/YYYY'
  )}`;

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex justify-between text-daintree text-xs">
        <p>{label}</p>
        {EffectiveDate && displayEffectiveDate && <p>{policyDate}</p>}
      </div>
      <div className="flex flex-row w-full h-6 rounded-xl border-daintree relative border-2">
        <span className="sr-only">
          {totalNotches === filledNotches
            ? 'complete'
            : `${filledNotches} of ${totalNotches} steps`}
        </span>
        {[...Array(totalNotches)].map((_, i) => {
          const isFilled = i + 1 <= filledNotches;

          return (
            <div
              key={i}
              className={classnames('flex-grow -m-px', {
                'xs:border-r-2': i + 1 < totalNotches,
                'rounded-l-xl': i === 0,
                'rounded-r-xl': i === totalNotches - 1,
                'bg-daintree border-daintree': isFilled,
                'bg-daintree-lightest border-daintree-light': !isFilled,
              })}
            />
          );
        })}
        {totalNotches === filledNotches && (
          <Icon
            name="happyFace"
            color="white"
            className="absolute top-0 right-0 w-5 h-5"
            size={null}
          />
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
