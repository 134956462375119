import React, { useEffect, useState } from 'react';
import { classnames } from 'tailwindcss-classnames';

import { Button } from 'src/common';
import { useModel } from 'src/lib/hooks';
import analytics, { EVENTS } from 'src/lib/analytics';
import { PAGES, WIND_MIT } from '../constants';

const FormSkipButton = ({
  FormType,
  nextPage,
  isCompleted,
  isFooter = true,
  className,
}) => {
  const forms = {
    uniformQuestionnairePage: PAGES.UniformQuestionnairePage,
    uniformUploadPage: PAGES.UniformUploadPage,
    buildingQuestionnairePage: PAGES.BuildingQuestionnairePage,
    buildingUploadPage: PAGES.BuildingUploadPage,
    windstormQuestionnairePage: PAGES.WindstormQuestionnairePage,
    windstormUploadPage: PAGES.WindstormUploadPage,
  };

  const { skipList } = useModel.session();
  const quote = useModel.quote();
  const { excludeWindMitForms, checkEligibility } = useModel.quote.dispatch();
  const { skip } = useModel.session.dispatch();

  const [{ loading, runEligibility }, setState] = useState({
    loading: false,
    runEligibility: false,
  });

  const onClick = async () => {
    switch (FormType) {
      case WIND_MIT.UNIFORM:
        skip({
          [forms.uniformQuestionnairePage]: true,
          [forms.uniformUploadPage]: true,
        });
        break;
      case WIND_MIT.BUILDING:
        skip({
          [forms.buildingQuestionnairePage]: true,
          [forms.buildingUploadPage]: true,
        });
        break;
      case WIND_MIT.WINDSTORM:
        skip({
          [forms.windstormQuestionnairePage]: true,
          [forms.windstormUploadPage]: true,
        });
        break;
      default:
        break;
    }

    const pageSet = new Set(Object.values(forms));
    const numFormsSkipped =
      skipList.filter((page) => pageSet.has(page)).length / 2 + 1;
    const skipAllForms = numFormsSkipped === 3;

    skip({
      [PAGES.SavingsPage]: skipAllForms,
    });

    setState({
      loading: true,
      runEligibility: skipAllForms,
    });
  };

  useEffect(() => {
    const onSkip = async () => {
      await excludeWindMitForms({ FormType });
      if (runEligibility) {
        analytics.track(EVENTS.COMPLETED_WIND_MIT, quote, {
          label: Object.values(WIND_MIT).filter(
            (val) => !!quote.WindMitigationFormData[val]
          ),
        });
        await checkEligibility();
      }
      await nextPage({ waitProfileUpdate: true });
    };

    if (loading) {
      onSkip();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    excludeWindMitForms,
    FormType,
    nextPage,
    loading,
    runEligibility,
    checkEligibility,
  ]);
  if (!isFooter) {
    return (
      <button className={classnames('underline')} onClick={onClick}>
        skip this discount
      </button>
    );
  }

  return (
    <Button
      className={classnames(
        'justify-center p-3.5',
        loading && 'border-2 border-mystic',
        className
      )}
      onClick={onClick}
      iconRight="arrowDoubleRight"
      color="blue-light"
      variant="small"
      loading={loading}
    >
      {isCompleted ? 'Remove This Form' : 'Skip This Form for Now'}
    </Button>
  );
};

export default FormSkipButton;
