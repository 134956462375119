import React from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'tailwindcss-classnames';

const Checkbox = ({
  name,
  control,
  children,
  containerClassName,
  error,
  rules,
  defaultValue = false,
  size = 'lg',
  layout,
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue={defaultValue}
    render={({ value, onChange, ...inputProps }) => {
      return (
        <label
          className={classNames(
            'flex',
            {
              'space-x-4': layout === 'vertical',
              'items-center space-x-2': layout === 'inline',
            },
            error && 'text-piper',
            containerClassName
          )}
        >
          <div>
            <input
              className={classNames(
                'appearance-none block min-w-1 border border-daintree rounded-md',
                'focus:ring-2 ring-daintree ring-offset-2',
                {
                  'h-4 w-4': size === 'sm',
                  'h-6 w-6': size === 'lg',
                },
                {
                  'border border-piper ring-piper-light': !!error,
                },
                value
                  ? 'bg-daintree bg-check bg-contain bg-center bg-no-repeat'
                  : 'bg-transparent'
              )}
              type="checkbox"
              name={name}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
              value={value}
              checked={value}
              {...inputProps}
            />
          </div>
          <div>
            {React.Children.map(children, (child) => {
              return React.cloneElement(
                child,
                { ...child.props, name, value, size, error },
                child.props.children
              );
            })}
          </div>
        </label>
      );
    }}
  />
);

const Group = ({ layout = 'vertical', className, children, error }) => {
  return (
    <div
      className={classNames(
        'flex',
        {
          'flex-col max-w-md space-y-4 mx-auto mt-6 mb-5':
            layout === 'vertical',
          'flex-wrap items-center p-2 w-full max-w-full': layout === 'inline',
        },
        className
      )}
    >
      {React.Children.map(children, (child) => {
        return React.cloneElement(
          child,
          { ...child.props, layout, error },
          child.props.children
        );
      })}
    </div>
  );
};

const Label = ({ className, children, ...props }) => (
  <p
    className={classNames(
      {
        'text-sm font-bold': props.size === 'lg',
        'text-xs': props.size === 'sm',
        'font-bold': props.size === 'sm' && props.value,
        'text-piper': !!props.error,
      },
      className
    )}
    {...props}
  >
    {children}
  </p>
);

const SubText = ({ children, ...props }) => (
  <p
    className={classNames({
      'text-xs': props.size === 'sm',
      'text-sm': props.size === 'lg',
      'text-piper': !!props.error,
    })}
    {...props}
  >
    {children}
  </p>
);

Checkbox.Group = Group;
Checkbox.Label = Label;
Checkbox.SubText = SubText;

export default Checkbox;
