import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import {
  BasicLayout,
  Card,
  Error,
  Footer,
  Form,
  Input,
  AddressInput,
  RadioInput,
} from 'src/common';
import { useFeatureFlags, useModel, useAuth0, useForm } from 'src/lib/hooks';
import products from 'src/products';
import analytics from 'src/lib/analytics';

const Landing = () => {
  const history = useHistory();
  const { enableExplicitResumption, enableCampaignRoute } = useFeatureFlags();
  const {
    control,
    register,
    handleSubmit,
    errors,
    formError,
    formState: { isSubmitting },
  } = useForm();
  const { newQuote } = useModel.quote.dispatch();
  const { Property, FirstName, LastName } = useModel.quote();
  const { policy } = useModel.session();
  const { logout } = useAuth0();

  useEffect(() => {
    analytics.page('Landing');
  }, []);

  const onSubmit = handleSubmit(async (formData) => {
    formData.FirstName = formData.FirstName.trim();
    formData.LastName = formData.LastName.trim();
    products?.['florida']?.['ho6']?.preload?.();
    await logout();
    await newQuote({
      WritingCompany: 'FSIC',
      ...formData,
    });
    history.push('/onboarding');
  });

  return (
    <BasicLayout displayNavElements={false}>
      <Card
        // icon="welcome"
        title="Welcome!"
        subtitle="We think buying insurance should be a breeze. Tell us a little about yourself and get your estimated premium instantly."
      >
        {formError && <Error>{formError}</Error>}
        <Form
          id="address-select-form"
          onSubmit={onSubmit}
          className="space-y-4"
        >
          <div>
            <RadioInput
              label={
                <strong className="text-daintree">
                  What type of property are you looking to insure?
                </strong>
              }
              name="FormType"
              containerClassName="flex flex-col items-center text-center font-bold mb-5 max-w-md mx-auto"
              radioInputClassName="space-x-0"
              control={control}
              rules={{ required: 'Required' }}
              error={errors?.FormType?.message}
            >
              <RadioInput.Option
                label="Condo"
                value="HO6"
                icon="condo"
                iconSize="3xl"
                className="xs:pr-4 pr-0"
              />
              <RadioInput.Option
                label="Rental"
                value="HO4"
                icon="rental"
                iconSize="3xl"
              />
              <RadioInput.Option
                label="Single-Family Home"
                value="HO3"
                icon="singleFamily"
                iconSize="3xl"
              />
            </RadioInput>
          </div>
          <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0 mt-2">
            <Input
              containerClassName="flex-1"
              label="First Name"
              name="FirstName"
              error={errors?.FirstName?.message}
              defaultValue={FirstName}
              ref={register({
                required: 'First name is required.',
                validate: (value) =>
                  value.trim().length > 0 || 'First name is required.',
              })}
              autocomplete="given-name"
            />
            <Input
              containerClassName="flex-1"
              label="Last Name"
              name="LastName"
              error={errors?.LastName?.message}
              defaultValue={LastName}
              ref={register({
                required: 'Last name is required.',
                validate: (value) =>
                  value.trim().length > 0 || 'Last name is required.',
              })}
              autocomplete="family-name"
            />
          </div>
          <AddressInput
            label="Address"
            name="Property.Location"
            control={control}
            error={errors?.Property?.Location?.message}
            rules={{
              required: 'Address is required.',
            }}
          />
        </Form>
        {((Property && !policy?.confirmationNumber) ||
          enableExplicitResumption) && (
          <div className="flex flex-row justify-center mt-1">
            <h2 className="text-sm">Already started a quote?</h2>
            <Link
              to="/resume"
              className="underline text-curious-blue-dark text-sm pl-1"
            >
              Get back to it!
            </Link>
          </div>
        )}
        {enableCampaignRoute && (
          <div className="flex flex-col items-center py-2">
            <Link to="/campaign" className="mt-1 underline text-center text-sm">
              Received an invitation code? Enter here
            </Link>
          </div>
        )}
      </Card>
      <Footer displayAssitance={false}>
        <Footer.Next
          onClick={onSubmit}
          rounded
          label="Get Started"
          loading={isSubmitting}
          fontFamily="font-heading"
        />
      </Footer>
    </BasicLayout>
  );
};

export default Landing;
