import { useSelector, useDispatch } from 'react-redux';

/* eslint-disable react-hooks/rules-of-hooks */
function createModelHook(key) {
  let model = () => useSelector((state) => state[key]);
  model.dispatch = () => useDispatch()[key];
  return model;
}

const useModel = {
  currentPhase: createModelHook('currentPhase'),
  quote: createModelHook('quote'),
  session: createModelHook('session'),
  user: createModelHook('user'),
  updated: createModelHook('updated'),
};

useModel.quote.defaults = (options = {}) => {
  const existingQuote = useModel.quote();
  const { pageIndex, furthestIndex } = useModel.session();

  const isHistorical = pageIndex < furthestIndex;
  return isHistorical || options?.forceDefaults ? existingQuote : {};
};

export default useModel;
