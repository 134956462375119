import React from 'react';
import classNames from 'tailwindcss-classnames';

import { Icon } from 'src/common';
import ProgressBar from './ProgressBar';

const Card = React.forwardRef(
  (
    {
      icon,
      title,
      subtitle,
      children,
      className,
      contentClassName,
      titleClassName,
      progressBar = null,
      displayEffectiveDate = true,
      ...containerProps
    },
    ref
  ) => {
    return (
      <div
        className="bg-white shadow-md relative"
        ref={ref}
        {...containerProps}
      >
        {progressBar && (
          <div className="hidden lg:block bg-mystic-light px-2 sm:px-14 py-5">
            <ProgressBar
              {...progressBar}
              displayEffectiveDate={displayEffectiveDate}
            />
          </div>
        )}
        <div
          className={classNames(
            'flex flex-col items-center relative px-2 py-8',
            'xs:px-6',
            'lg:p-6',
            className
          )}
        >
          <div
            className={classNames(
              'flex flex-col items-center max-w-md text-center mb-5',
              contentClassName
            )}
          >
            {icon && <Icon name={icon} size="3xl" className="mb-5" />}
            <h1 className={titleClassName}>{title}</h1>
            {subtitle && <h2 className="mt-4 text-daintree">{subtitle}</h2>}
          </div>
          <div className="w-full sm:px-6">{children}</div>
        </div>
      </div>
    );
  }
);

export default Card;
