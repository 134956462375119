import { classnames } from 'tailwindcss-classnames';
import { useResponsiveVariant } from 'src/lib/hooks';
import Button from '../Button';

const Next = ({
  className,
  onClick,
  icon = 'chevronRight',
  hideLabel,
  label: propLabel,
  loading,
  disabled,
  rounded = false,
  color = 'light',
  ...buttonProps
}) => {
  const defaultLabel = useResponsiveVariant({
    default: 'Next',
    xs: 'Next Step',
  });

  const label = propLabel ?? defaultLabel;

  return (
    <Button
      className={classnames(
        !rounded && 'rounded-l-none',
        'justify-center',
        icon && 'pr-3 xs:pr-3',
        hideLabel && 'w-16 pl-0 xs:pl-0',
        (disabled || loading) && 'border-2 border-mystic',
        'focus:ring-offset-daintree',
        className
      )}
      fontFamily="font-heading"
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      iconRight={icon}
      color={disabled || loading ? 'dark' : color}
      aria-label={label}
      {...buttonProps}
    >
      {!hideLabel && label}
    </Button>
  );
};

export default Next;
