import axios from 'axios';

import config from 'src/config';
import store from 'src/lib/store';

const getHeaders = (options = {}, secure = false) => {
  const headers = { ...options.headers };

  if (config.skyway.locked === 'true') {
    headers['client_id'] = config.skyway.clientId;
    headers['client_secret'] = config.skyway.clientSecret;
  }

  if (secure) {
    const token = store.getState().user?.token;
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
};

const mockApi = {
  putProfileMockCall: () =>
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
  patchProfileMockCall: () =>
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
  postInterestMockCall: () =>
    new Promise((res) => {
      setTimeout(res, 3000);
    }),
};

const api = {
  get: (path, options = {}) =>
    axios.get(config.skyway.baseUrl + path, {
      ...options,
      headers: getHeaders(options),
    }),
  post: (path, body, options = {}) =>
    axios.post(config.skyway.baseUrl + path, body, {
      ...options,
      headers: getHeaders(options),
    }),
  put: (path, body, options = {}) =>
    axios.put(config.skyway.baseUrl + path, body, {
      ...options,
      headers: getHeaders(options),
    }),
  patch: (path, body, options = {}) =>
    axios.patch(config.skyway.baseUrl + path, body, {
      ...options,
      headers: getHeaders(options),
    }),
  delete: (path, options = {}) =>
    axios.delete(config.skyway.baseUrl + path, {
      ...options,
      headers: getHeaders(options),
    }),
  ...mockApi,
};

api.secure = {
  get: (path, options = {}) =>
    api.get('/secure' + path, {
      ...options,
      headers: getHeaders(options, true),
    }),
  post: (path, body, options = {}) =>
    api.post('/secure' + path, body, {
      ...options,
      headers: getHeaders(options, true),
    }),
  put: (path, body, options = {}) =>
    api.put('/secure' + path, body, {
      ...options,
      headers: getHeaders(options, true),
    }),
  patch: (path, body, options = {}) =>
    api.patch('/secure' + path, body, {
      ...options,
      headers: getHeaders(options, true),
    }),
  delete: (path, options = {}) =>
    api.delete('/secure' + path, {
      ...options,
      headers: getHeaders(options, true),
    }),
  ...mockApi,
};

api.fakeLoad = (data) =>
  new Promise((resolve) => {
    setTimeout(() => resolve({ data }), 1000);
  });

export { api };

const useApi = () => api;

export default useApi;
