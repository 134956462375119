import React from 'react';
import { BasicLayout, Card, Footer } from 'src/common';
import Button from 'src/common/Button';

const AgentReferral = ({ handleStartOver }) => {
  return (
    <BasicLayout displayNavElements={false}>
      <Card
        icon="beachClosed"
        title="Uh oh :("
        subtitle="We're missing some important information and can't continue this quote process. Please reach out to a UPC Insurance agent so they can help you complete and purchase your policy."
      >
        <div className="flex justify-center">
          <Button
            className="flex self-center text-sm"
            onClick={() =>
              window.open(
                'https://www.upcinsurance.com/get-coverage/find-an-agent'
              )
            }
            color="dark"
            variant="rectangular"
          >
            UPC Agent Search
          </Button>
        </div>
      </Card>
      <Footer>
        <Footer.Next
          rounded
          label="Exit"
          onClick={handleStartOver}
          icon={null}
        />
      </Footer>
    </BasicLayout>
  );
};

export default AgentReferral;
