import React, { useEffect, useLayoutEffect, useState } from 'react';
import states, { military } from 'src/lib/states';
import countries from 'src/lib/countries';
import classNames from 'tailwindcss-classnames';
import get from 'lodash.get';

import { Input, MaskedInput, AddressInput, Select } from 'src/common';

const AutocompleteAddress = ({
  name,
  label,
  includeDomestic = true,
  international = true,
  includeAllCountries = true,
  excludeMilitaryStates = false,
  isHistorical = false,
  setError,
  errors,
  control,
  watch,
  register,
  column = false,
  optional = false,
}) => {
  const [manual, setManual] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);

  const country = watch(`${name}.Country`);
  const hasStates = !international || country === 'US' || country === 'CA';

  const countryOptions = (
    includeAllCountries ? countries.full : countries.mailing
  )
    .filter((c) => includeDomestic || c.value !== 'US')
    .map((c) => ({
      id: c.value,
      title: c.name,
    }));

  useLayoutEffect(() => {
    if (isHistorical) {
      setManual(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const countryOpt = international ? country : 'US';
    const stateOpts =
      countryOpt === 'US' && !excludeMilitaryStates
        ? [...states[countryOpt], ...military]
        : states[countryOpt];

    setStateOptions(
      hasStates
        ? stateOpts.map((s) => ({
            id: s.value,
            title: s.name,
          }))
        : []
    );
  }, [country, hasStates, international, excludeMilitaryStates]);

  const enterManually = () => {
    setManual(true);

    if (errors?.[name]?.Address) {
      setError(`${name}.Address1`, {
        type: 'manual',
        message: 'Address is required.',
      });

      setError(`${name}.City`, {
        type: 'manual',
        message: `${hasStates ? 'City' : 'City/Locality'} is required.`,
      });

      if (hasStates) {
        setError(`${name}.State`, {
          type: 'manual',
          message: `${
            country === 'US' || country === 'CA' ? 'State' : 'Province'
          } is required.`,
        });
      }
    }
  };

  const labelName = label ? `${label} ` : '';

  return (
    <div className="flex flex-col space-y-4 w-full">
      {international && (
        <Select
          containerClassName="flex flex-col mx-2"
          label={labelName + `Country`}
          name={`${name}.Country`}
          error={get(errors, `${name}.Country.message`)}
          control={control}
          rules={{
            required: { value: !optional, message: 'Country is required.' },
          }}
          options={countryOptions}
          optional={optional}
        />
      )}
      {manual ? (
        <>
          <Input
            label={labelName + `Address`}
            name={`${name}.Address1`}
            containerClassName="flex flex-col flex-grow mx-2"
            error={get(errors, `${name}.Address1.message`)}
            ref={register({
              required: { value: !optional, message: 'Address is required.' },
            })}
            autocomplete="street-address"
            optional={optional}
          />
          <Input
            label={hasStates ? labelName + `City` : labelName + `City/Locality`}
            name={`${name}.City`}
            containerClassName="flex flex-col flex-grow mx-2"
            error={get(errors, `${name}.City.message`)}
            ref={register({
              required: {
                value: !optional,
                message: `${hasStates ? 'City' : 'City/Locality'} is required.`,
              },
            })}
            autocomplete="address-level2"
            optional={optional}
          />
          <div className="flex flex-wrap">
            {hasStates ? (
              <Select
                label={
                  country === 'US' || !international
                    ? labelName + `State`
                    : labelName + `Province`
                }
                name={`${name}.State`}
                control={control}
                error={get(errors, `${name}.State.message`)}
                options={stateOptions}
                rules={{
                  required: {
                    value: !optional,
                    message: `${
                      international ? 'Province' : 'State'
                    } is required.`,
                  },
                }}
                containerClassName={classNames(
                  'flex flex-col flex-grow px-2',
                  column ? 'w-full mb-4' : 'w-full sm:w-1/2 mb-4 sm:mb-0'
                )}
                autocomplete="address-level1"
                optional={optional}
              />
            ) : (
              <Input
                label={
                  <span>
                    {labelName + `State/Province`}
                    {hasStates ? (
                      ''
                    ) : (
                      <span className="font-normal"> (optional)</span>
                    )}
                  </span>
                }
                name={`${name}.State`}
                error={get(errors, `${name}.State.message`)}
                ref={register()}
                containerClassName={classNames(
                  'flex flex-col flex-grow px-2',
                  column ? 'w-full mb-4' : 'w-full sm:w-1/2 mb-4 sm:mb-0'
                )}
                autocomplete="address-level1"
                optional
              />
            )}
            {country === 'US' || !international ? (
              <MaskedInput
                label={labelName + `Zip Code`}
                name={`${name}.ZipCode`}
                control={control}
                error={get(errors, `${name}.ZipCode.message`)}
                mask="99999"
                rules={{
                  required: {
                    value: !optional,
                    message: 'Zip Code is required.',
                  },
                }}
                containerClassName="flex flex-col flex-grow px-2 w-full sm:w-1/2"
                autocomplete="postal-code"
                optional={optional}
              />
            ) : (
              <Input
                label={
                  <span>
                    {labelName + `Postal Code`}
                    {hasStates ? (
                      ''
                    ) : (
                      <span className="font-normal"> (optional)</span>
                    )}
                  </span>
                }
                name={`${name}.ZipCode`}
                error={get(errors, `${name}.ZipCode.message`)}
                containerClassName="flex flex-col flex-grow px-2 w-full sm:w-1/2"
                ref={register({
                  required: {
                    value: hasStates && !optional,
                    message: 'Postal Code is required.',
                  },
                })}
                autocomplete="postal-code"
                optional
              />
            )}
          </div>
        </>
      ) : (
        <div className="flex flex-col">
          <AddressInput
            label={labelName + `Address`}
            name={`${name}.Address`}
            disabled={!country && international}
            placeholder={!country && international && 'Select a country first'}
            containerClassName="mx-2"
            column={column}
            control={control}
            error={get(errors, `${name}.Address.message`)}
            rules={{
              required: { value: !optional, message: 'Address is required.' },
            }}
            international={international}
            country={country}
            autocomplete="street-address"
            optional={optional}
          />
        </div>
      )}
      {!manual && (
        <div className="flex flex-col items-center">
          <div className="flex text-daintree text-sm">
            Can't find your address?
          </div>
          <div className="flex">
            <button
              type="button"
              onClick={enterManually}
              className="underline text-curious-blue-dark text-sm"
            >
              Enter it manually.
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AutocompleteAddress;
