import { classnames } from 'tailwindcss-classnames';
import { useResponsiveVariant } from 'src/lib/hooks';
import Button from '../Button';

const noop = () => {};

const Back = ({
  className,
  onClick,
  hideLabel,
  label: propLabel,
  icon = 'chevronLeft',
  disabled = false,
  rounded = false,
}) => {
  const defaultLabel = useResponsiveVariant({
    default: 'Back',
    xs: 'Go Back',
  });

  const label = propLabel ?? defaultLabel;

  return (
    <Button
      className={classnames(
        !rounded && 'rounded-r-none',
        'justify-center',
        icon && 'pl-3 xs:pl-3',
        hideLabel && 'w-16 pr-0 xs:pr-0',
        'focus:ring-offset-daintree',
        className
      )}
      fontFamily="font-heading"
      onClick={onClick ?? noop}
      disabled={disabled}
      iconLeft={icon}
      aria-label={label}
    >
      {!hideLabel && label}
    </Button>
  );
};

export default Back;
