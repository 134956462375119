import React from 'react';
import classNames from 'tailwindcss-classnames';

const Input = React.forwardRef(
  (
    {
      name,
      id,
      type,
      label,
      error,
      containerClassName,
      className,
      optional = false,
      ...props
    },
    ref
  ) => {
    const inputType = type === 'dollar' ? 'number' : type;
    return (
      <div className={containerClassName}>
        <div className="flex flex-col relative">
          {label && (
            <label
              htmlFor={id ?? name}
              className={classNames(
                'font-bold text-xs absolute -top-2 left-4',
                error ? 'text-piper' : 'text-daintree'
              )}
            >
              <span className="bg-white px-1">{label}</span>
            </label>
          )}
          <div className="flex items-center">
            {type === 'dollar' && (
              <div className="text-sm absolute left-2">$</div>
            )}
            <input
              className={classNames(
                'w-full h-11 px-4 text-sm text-daintree border rounded-lg focus:ring-4 transition placeholder-dove-gray',
                error
                  ? 'border-piper ring-piper-light'
                  : 'border-daintree ring-daintree-faded',
                type === 'dollar' && 'pl-5',
                className
              )}
              required={!optional}
              ref={ref}
              aria-invalid={error ? 'true' : 'false'}
              {...(error && { 'aria-describedby': `${id ?? name}-error` })}
              type={inputType ?? 'text'}
              id={id ?? name}
              name={name}
              {...props}
            />
          </div>
          {error && (
            <span
              id={`${id ?? name}-error`}
              className="text-piper text-xs mt-1 ml-4"
            >
              {error}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default Input;
