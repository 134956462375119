const military = [
  { value: 'AA', name: 'Armed Forces (AA)' },
  { value: 'AE', name: 'Armed Forces (AE)' },
  { value: 'AP', name: 'Armed Forces (AP)' },
];

const states = {
  US: [
    { value: 'AL', name: 'Alabama' },
    { value: 'AK', name: 'Alaska' },
    { value: 'AS', name: 'American Samoa' },
    { value: 'AZ', name: 'Arizona' },
    { value: 'AR', name: 'Arkansas' },
    { value: 'CA', name: 'California' },
    { value: 'CO', name: 'Colorado' },
    { value: 'CT', name: 'Connecticut' },
    { value: 'DE', name: 'Delaware' },
    { value: 'DC', name: 'District of Columbia' },
    { value: 'FL', name: 'Florida' },
    { value: 'GA', name: 'Georgia' },
    { value: 'GU', name: 'Guam' },
    { value: 'HI', name: 'Hawaii' },
    { value: 'ID', name: 'Idaho' },
    { value: 'IL', name: 'Illinois' },
    { value: 'IN', name: 'Indiana' },
    { value: 'IA', name: 'Iowa' },
    { value: 'KS', name: 'Kansas' },
    { value: 'KY', name: 'Kentucky' },
    { value: 'LA', name: 'Louisiana' },
    { value: 'MH', name: 'Marshall Islands' },
    { value: 'MA', name: 'Massachusetts' },
    { value: 'MI', name: 'Michigan' },
    { value: 'FM', name: 'Micronesia, Federated States Of' },
    { value: 'MN', name: 'Minnesota' },
    { value: 'MS', name: 'Mississippi' },
    { value: 'MO', name: 'Missouri' },
    { value: 'MT', name: 'Montana' },
    { value: 'NE', name: 'Nebraska' },
    { value: 'NV', name: 'Nevada' },
    { value: 'NH', name: 'New Hampshire' },
    { value: 'NJ', name: 'New Jersey' },
    { value: 'NM', name: 'New Mexico' },
    { value: 'NY', name: 'New York' },
    { value: 'NC', name: 'North Carolina' },
    { value: 'ND', name: 'North Dakota' },
    { value: 'MP', name: 'Northern Mariana Islands' },
    { value: 'OH', name: 'Ohio' },
    { value: 'OK', name: 'Oklahoma' },
    { value: 'OR', name: 'Oregon' },
    { value: 'PW', name: 'Palau' },
    { value: 'PA', name: 'Pennsylvania' },
    { value: 'PR', name: 'Puerto Rico' },
    { value: 'RI', name: 'Rhode Island' },
    { value: 'SC', name: 'South Carolina' },
    { value: 'SD', name: 'South Dakota' },
    { value: 'TN', name: 'Tennessee' },
    { value: 'TX', name: 'Texas' },
    { value: 'UT', name: 'Utah' },
    { value: 'VT', name: 'Vermont' },
    { value: 'VI', name: 'Virgin Islands, U.S.' },
    { value: 'VA', name: 'Virginia' },
    { value: 'WA', name: 'Washington' },
    { value: 'WV', name: 'West Virginia' },
    { value: 'WI', name: 'Wisconsin' },
    { value: 'WY', name: 'Wyoming' },
  ],
  CA: [
    { value: 'AB', name: 'Alberta' },
    { value: 'BC', name: 'British Columbia' },
    { value: 'MB', name: 'Manitoba' },
    { value: 'NB', name: 'New Brunswick' },
    { value: 'NL', name: 'Newfoundland and Labrador' },
    { value: 'NT', name: 'Northwest Territories' },
    { value: 'NS', name: 'Nova Scotia' },
    { value: 'NU', name: 'Nunavut' },
    { value: 'ON', name: 'Ontario' },
    { value: 'PE', name: 'Prince Edward Island' },
    { value: 'QC', name: 'Quebec' },
    { value: 'SK', name: 'Saskatchewan' },
    { value: 'YT', name: 'Yukon Territory' },
  ],
};

export default states;

export { military };
