import React, { useState } from 'react';

import { BasicLayout, Footer, Card, Error } from 'src/common';
import Button from 'src/common/Button';
import { useErrorHandling, useModel } from 'src/lib/hooks';
import analytics, { EVENTS } from 'src/lib/analytics';

const ErrorDecline = ({
  quote,
  title = 'Uh oh :(',
  description = 'Looks like we cannot offer you a condo insurance policy at this time.',
  declinationNotice,
  handleStartOver,
}) => {
  const { ID } = useModel.quote();
  const { downloadAttachment } = useModel.quote.dispatch();
  const handleError = useErrorHandling();
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState(null);

  const handleDownload = async () => {
    try {
      setError(null);
      setIsDownloading(true);
      await downloadAttachment({ ID, attachment: declinationNotice });
      analytics.track(EVENTS.DOWNLOADED_FILE, quote, {
        label: declinationNotice.Title,
      });
      setIsDownloading(false);
    } catch (err) {
      setIsDownloading(false);
      setError(await handleError(err));
    }
  };

  return (
    <BasicLayout displayNavElements={false}>
      <Card icon="beachClosed" title={title} subtitle={description}>
        {error && <Error>{error}</Error>}
        {!!declinationNotice ? (
          <div className="flex justify-center">
            <Button
              className="flex self-center text-sm"
              onClick={handleDownload}
              loading={isDownloading}
              color="dark"
              variant="rectangular"
            >
              Learn More
            </Button>
          </div>
        ) : (
          <a
            href="https://www.skyway.com/decline"
            className="flex justify-center text-curious-blue-dark underline -mt-5"
          >
            Why not?
          </a>
        )}
      </Card>
      <Footer>
        <Footer.Next
          rounded
          label="Exit"
          onClick={handleStartOver}
          icon={null}
        />
      </Footer>
    </BasicLayout>
  );
};

export default ErrorDecline;
