import { classnames } from 'tailwindcss-classnames';

const DetailItem = ({
  title,
  children,
  titleClassName,
  textClassName,
  fontBold = true,
}) => (
  <>
    <dt
      className={classnames(
        'text-daintree text-sm',
        fontBold ? 'font-bold' : 'font-light',
        titleClassName
      )}
    >
      {title}
    </dt>
    <dd
      className={classnames(
        'text-daintree leading-4 text-sm pb-3',
        textClassName
      )}
    >
      {children}
    </dd>
  </>
);

export default DetailItem;
