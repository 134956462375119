import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { getPersistor } from '@rematch/persist';

import { LoadingContent, Viewport, ErrorBoundary } from 'src/common';
import Refocus from 'src/lib/Refocus';
import store from 'src/lib/store';
import App from './App';
import './index.css';
import config from './config';
import 'src/lib/analytics';

if (config.a11y === 'true') {
  import('@axe-core/react').then(({ default: axe }) => {
    setInterval(() => axe(React, ReactDOM, 1000), 1000);
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <Router>
        <Refocus>
          <Viewport>
            <ErrorBoundary>
              <PersistGate
                loading={<LoadingContent />}
                persistor={getPersistor()}
              >
                <App />
              </PersistGate>
            </ErrorBoundary>
          </Viewport>
        </Refocus>
      </Router>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
