/* eslint-disable jsx-a11y/role-supports-aria-props */
import { useSelect } from 'downshift';
import { useController } from 'react-hook-form';
import classNames from 'tailwindcss-classnames';

import { DropdownMenu, Icon } from 'src/common';

const Select = ({
  id,
  name,
  label,
  placeholder,
  options = [],
  className,
  containerClassName,
  error,
  control,
  rules,
  optional = false,
}) => {
  const transform = {
    input: (v) => options.find((o) => o.id === v),
    output: (v) => v?.selectedItem?.id,
  };

  const {
    field: { onChange, value, ref },
  } = useController({
    name,
    control,
    rules,
  });

  const {
    isOpen,
    selectedItem,
    highlightedIndex,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: options,
    onSelectedItemChange: (v) => onChange(transform.output(v)),
    defaultSelectedItem: transform.input(value),
    selectedItem: transform.input(value),
    itemToString: (i) => (i ? i.title : ''),
    id: id ?? name,
  });

  return (
    <div
      className={classNames(
        containerClassName ?? 'flex flex-col w-full',
        'relative'
      )}
    >
      {label && (
        <label
          htmlFor={id ?? name}
          className={classNames(
            'font-bold text-xs bg-white px-1 absolute -mt-2 ml-4',
            error ? 'text-piper' : 'text-daintree'
          )}
          {...getLabelProps()}
        >
          {label}
        </label>
      )}
      <div>
        <button
          type="button"
          {...getToggleButtonProps({ ref, role: 'combobox' })}
          id={id ?? name}
          {...(error && { 'aria-describedby': `${id ?? name}-error` })}
          className={classNames(
            'flex justify-between items-center w-full text-sm text-daintree border rounded-lg transition px-4 h-11 cursor-default',
            'focus:outline-none focus:ring-4',
            error
              ? 'border-piper ring-piper-light'
              : 'border-daintree ring-daintree-faded',
            className
          )}
          aria-required={!optional}
        >
          <span
            className={classNames('truncate', {
              'text-dove-gray': selectedItem?.id === undefined,
            })}
          >
            {selectedItem?.title ?? placeholder}
          </span>
          <Icon
            name="chevronDown"
            size="sm"
            className={classNames('transform transition', {
              'rotate-180': isOpen,
            })}
            color={!!error && 'piper'}
          />
        </button>
        <DropdownMenu
          isOpen={isOpen}
          error={error}
          options={options}
          highlightedIndex={highlightedIndex}
          getMenuProps={getMenuProps}
          getItemProps={getItemProps}
          getItemValue={(i) => i.title}
        />
      </div>
      {error && (
        <span
          id={error ? `${id ?? name}-error` : undefined}
          className="text-piper text-xs mt-1 ml-4"
        >
          {error}
        </span>
      )}
    </div>
  );
};

export default Select;
