import React from 'react';
import { useHistory } from 'react-router-dom';

import { BasicLayout, Card, Button, Footer } from 'src/common';

const ResumptionError = ({ error, retry }) => {
  const history = useHistory();

  const handleGoBack = () => {
    history.push('/');
  };

  return (
    <BasicLayout displayNavElements={false}>
      <Card icon="seashells" title="Aw, shucks!" subtitle={error} />
      <div className="flex justify-center">
        <Button
          color="dark"
          variant="rectangular"
          className="w-36 justify-center"
          onClick={retry}
        >
          Try Again
        </Button>
      </div>
      <Footer>
        <Footer.Back rounded label="No Thanks" onClick={handleGoBack} />
      </Footer>
    </BasicLayout>
  );
};

export default ResumptionError;
